import { generatePath } from 'react-router'
import qs from 'qs'

import { ROUTE } from '../config'

export const generatePublicationLink = ({
  isPdfPublication,
  searchQuery,
  resourceKey,
  isFolder,
  matches,
  extraQueryParams,
}) => {
  const queryParamsStr = qs.stringify(extraQueryParams || {}, { skipNulls: true })
  const queryParamsFormatted = queryParamsStr ? `?${queryParamsStr}` : ''

  const queryParamsStrExt = qs.stringify({ ...(extraQueryParams || {}), q: searchQuery }, { skipNulls: true })
  const queryParamsStrExtFormatted = queryParamsStrExt ? `?${queryParamsStrExt}` : ''

  if (isPdfPublication) {
    return (searchQuery && matches.length > 0)
      ? `${generatePath(ROUTE.PDF_PUBLICATION_MATCHES, { resourceKey })}${queryParamsStrExtFormatted}`
      : `${generatePath(ROUTE.PDF_PUBLICATION, { resourceKey })}${queryParamsFormatted}`
  }
  return `${isFolder ? ROUTE.FOLDER : ROUTE.TOPIC}/${resourceKey}${queryParamsFormatted}`
}

export const isWarningLightsPublication = item => item.publicationType === 'WL' || item.publicationSubType === 'Icon Glossary'

export const parseLocationPreviewParams = location => {
  const parsedParams = qs.parse((location?.search || '').slice(1))
  return parsedParams?.preview ? { preview: true } : undefined
}
