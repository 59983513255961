import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ReactModal from 'react-modal'
import classNames from 'classnames'
import TermsContent from '../components/TermsContent'

import '../styles/TermsOfServiceModal.scss'

export default class TermsOfServiceModal extends Component {

  constructor(props) {
    super(props)

    ReactModal.setAppElement('body')

    this.state = {
      overlayBottom: 0,
      containerHeight: 0,
      innerHeight: 0,
      overlayHeight: 0
    }
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    acceptedConditions: PropTypes.bool,
    currentBrand: PropTypes.string.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    handleCloseModal: PropTypes.func.isRequired
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      this.props.handleCloseModal()
    }
  };


  render() {
    const {isOpen, acceptedConditions, handleCloseModal, currentBrand} = this.props

    return (
      <ReactModal isOpen={isOpen}
        onRequestClose={acceptedConditions ? handleCloseModal : null}
        className="TermsOfServiceModal"
        overlayClassName={classNames("TermsOfServiceModalOverlay", currentBrand ? currentBrand.toLowerCase() : "generic")}>
        <div className="terms-head">
          <div className="terms-logo" />
          <h1 className="terms-title">Quick Guide <p>Terms Of Service</p></h1>
        </div>
        <TermsContent currentBrand={currentBrand}/>
        <div className="terms-action">
          <button className="accept PrimaryButton" onClick={() => {
            handleCloseModal()
          }}>{!acceptedConditions ? "Accept" : "Close"}</button>
        </div>
      </ReactModal>
    )
  }
}
