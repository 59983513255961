import React from 'react'

export function zoomOut() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 17 17" style={{enableBackground:"new 0 0 17 17"}} xmlSpace="preserve">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g className="fill" fillRule="nonzero">
          <path d="M7.61380907,3.87923862e-06 C4.23319113,-0.000822567687 1.25715015,2.22858904 0.307259101,5.47348607 C-0.642631954,8.7183831 0.661290478,12.2010503 3.5085505,14.0238662 C6.35581053,15.8466821 10.0639958,15.5727664 12.6126057,13.3513692 L16.1012395,16.8457746 C16.3068434,17.0514085 16.6401932,17.0514085 16.8457971,16.8457746 C17.051401,16.6401407 17.051401,16.3067423 16.8457971,16.1011084 L13.3519014,12.6093343 C15.314121,10.3607754 15.7803896,7.17234635 14.544237,4.45592832 C13.3080843,1.73951028 10.5979067,-0.00300065338 7.61380907,3.87923862e-06 Z M1.05486175,7.61243979 C1.05300406,4.64805358 3.03914243,2.05078727 5.90014537,1.27627911 C8.7611483,0.501770943 11.7859775,1.74250761 13.2793671,4.30311807 C14.7727566,6.86372853 14.363558,10.1078303 12.2811065,12.2172662 L12.2469042,12.2462108 L12.2179638,12.2804181 C10.3361682,14.1389774 7.52213918,14.6847762 5.08212988,13.6644585 C2.64212058,12.6441408 1.05396014,10.2575163 1.05486175,7.61243979 L1.05486175,7.61243979 Z"/>
          <path d="M7.9950495,7.0049505 L7.0049505,7.0049505 L5.4950495,7.0049505 C5.22164121,7.0049505 5,7.22659171 5,7.5 C5,7.77340829 5.22164121,7.9950495 5.4950495,7.9950495 L7.0049505,7.9950495 L7.9950495,7.9950495 L9.5049505,7.9950495 C9.77835879,7.9950495 10,7.77340829 10,7.5 C10,7.22659171 9.77835879,7.0049505 9.5049505,7.0049505 L7.9950495,7.0049505 Z"/>
        </g>
      </g>
    </svg>
  )
}
