import React from 'react'

export function listImage() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" style={{enableBackground: "new 0 0 17 16"}} xmlSpace="preserve">
      <g className="fill" fillRule="nonzero">
        <path d="M14 0H2.8C1.44 0 .4 1.04.4 2.4v11.2c0 1.36 1.04 2.4 2.4 2.4H14c1.36 0 2.4-1.04 2.4-2.4V2.4C16.4 1.04 15.36 0 14 0zM2 2.4c0-.48.32-.8.8-.8H14c.48 0 .8.32.8.8v6.08l-2.64-2.64a.773.773 0 0 0-1.12 0l-8.48 8.48A.768.768 0 0 1 2 13.6V2.4zm12 12H4.72l6.88-6.88 3.2 3.2v2.88c0 .48-.32.8-.8.8z"/>
        <path d="M5.6 7.2c1.12 0 2-.88 2-2s-.88-2-2-2-2 .88-2 2 .88 2 2 2zm0-2.4c.24 0 .4.16.4.4 0 .24-.16.4-.4.4-.24 0-.4-.16-.4-.4 0-.24.16-.4.4-.4z"/>
      </g>
    </svg>
  )
}
