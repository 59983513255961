import React from 'react'

export function pdfInfiniti() {
  return (
    <svg
      style={{ enableBackground: 'new 0 0 1000 1000' }}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 1000 1000'
      xmlSpace='preserve'
    >
      <g id='Layer_1'></g>
      <g id='Layer_2'>
        <path
          d='M366.3749,41.6701L166.6666,241.3788v633.6246L250,958.33h583.3334V41.6701H366.3749z M354.1688,112.8015v116.3703
		H237.8027L354.1688,112.8015z M791.6599,916.6633H208.3405V270.8385h187.4949V83.3368h395.8244V916.6633z'
        />
      </g>
    </svg>
  )
}
