import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import VehicleModelSelector from "./VehicleModelSelector"
import Loader from "./Loader"
import { getPathAlias, getModelGroups, getModelsOfGroup, getVehiclesForYear, getVehicleForBrand } from 'tw-oi-core/utils/vehicle'

import classNames from 'classnames'
import MotionScroll from './MotionScroll'
import { MESSAGE, UI, ROUTE, BRAND_INFINITI, BRAND_NISSAN } from '../config'

import '../styles/VehicleGroupSelector.scss'

export default class VehicleGroupSelector extends Component {

  static propTypes = {
    models: PropTypes.array,
    currentYear: PropTypes.string.isRequired,
    currentGroup: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    setVehicleModel: PropTypes.func.isRequired,
    setVehicleGroup: PropTypes.func.isRequired,
    vehicles: PropTypes.array,
    label: PropTypes.string
  }

  modelContainer = null
  groupsOffset = []
  scrollContainer = null
  scrollInner = null
  scrollTimeout = null
  scrollType = UI.SCROLL_TYPE_USER
  isScrolling = false

  constructor(props) {
    super(props)

    this.state = {
      scrollToTarget: 0,
      scrollHeight: 0,
      innerHeight: 0,
      containerHeight: 0,
      animateScroll: false,
      currentGroup: props.currentGroup
    }

    this.handleScroll = this.handleScroll.bind(this)

  }

  scrollToOpenedGroup() {
    const { currentGroup } = this.state
    const { vehicles, currentYear, currentBrand } = this.props

    if (currentGroup) {
      // Programmatically scroll container
      this.scrollType = UI.SCROLL_TYPE_MANUAL

      const brandedVehicles = getVehicleForBrand(vehicles, currentBrand)
      const vehiclesForYear = getVehiclesForYear(brandedVehicles, currentYear)
      const groups = getModelGroups(vehiclesForYear)

      const currentGroupIndex = groups.indexOf(currentGroup)
      if (currentGroupIndex !== -1 && this.groupsOffset[currentGroupIndex]) {
        this.setState({
          scrollToTarget:this.groupsOffset[currentGroupIndex] - UI.GROUP_MARGIN,
          animateScroll: true
        })
      }
    } else {
      if (this.state.scrollToTarget) {
        // Programmatically scroll container
        this.scrollType = UI.SCROLL_TYPE_MANUAL
      }

      this.setState({
        scrollToTarget: 0,
        animateScroll: true
      })
    }
  }

  handleScroll() {
    //on init always firing scrollTop with 0, so ignore
    if (!this.scrollTimeout && !this.scrollContainer.scrollTop) return

    // listen only for user scroll, not programmatically
    if (this.scrollType !== UI.SCROLL_TYPE_USER) return

    this.isScrolling = true

    if (this.scrollTimeout) window.clearTimeout(this.scrollTimeout)

    this.scrollTimeout = window.setTimeout(this.onScrollEnds, UI.ANIMATION_DELAY)
  }

  onScrollEnds = () => {
    // react only for user scroll, not programmatically (need to re-check it inside Timeout for Android)
    if (this.scrollType !== UI.SCROLL_TYPE_USER || !this.scrollContainer) return

    this.isScrolling = false

    // User scrolls container
    this.setState({
      scrollToTarget: this.scrollContainer.scrollTop,
      animateScroll: false
    })
  }

  handleTouchMove() {
    // listen only for manual scroll to interrupt
    if (this.scrollType !== UI.SCROLL_TYPE_MANUAL) return

    // interrupt Motion animation when user scroll the area
    this.scrollType = UI.SCROLL_TYPE_USER

    this.setState({
      animateScroll: false
    })
  }

  calculateGroupOffsets() {
    if (this.scrollContainer) {
      const groupContainers = this.scrollContainer.getElementsByClassName('group')
      this.groupsOffset = []

      // get all groups offsetTop before any opened
      for (let i = 0; i < groupContainers.length; i++) {
        this.groupsOffset.push(groupContainers[i].offsetTop)
      }
    }
  }

  componentDidMount() {
    if (!this.props.models.length) return

    this.calculateGroupOffsets()

    setTimeout(() => {

      this.setState({
        scrollHeight: this.scrollContainer.offsetHeight,
        innerHeight: this.scrollInner.offsetHeight,
        containerHeight: this.modelContainer ? this.modelContainer.offsetHeight : 0
      })

      this.scrollToOpenedGroup()
    })

    if (this.scrollContainer) this.scrollContainer.addEventListener('scroll', this.handleScroll)

  }

  componentDidUpdate(prevProps, prevState) {
    const { currentGroup } = this.props

    // switch from user type scrolling to manual on scrollToTarget change
    if (prevState.scrollToTarget !== this.state.scrollToTarget) {
      this.scrollType = UI.SCROLL_TYPE_MANUAL
    }

    // passed currentGroup changed
    if (prevProps.currentGroup !== currentGroup) {
      this.setState({currentGroup, containerHeight: 0
      })
    }

    // passed currentYear changed
    if (prevProps.currentYear !== this.props.currentYear) {

      // interrupt user scrolling animation with disabling scroll
      if (this.isScrolling) this.scrollContainer.classList.add('stop')

      if (this.state.currentGroup) {
        this.setState({currentGroup: null})
      }
      setTimeout(() => this.setState({currentGroup: this.props.currentGroup}), UI.ANIMATION_DELAY)
    }

    // model list changed
    const ifModelsUpdated = JSON.stringify(this.props.models) !== JSON.stringify(prevProps.models)

    // enabling scroll after interrupting animation
    if (ifModelsUpdated) {
      this.scrollContainer.classList.remove('stop')
    }

    // current group closed or model list changed
    if (prevState.currentGroup !== this.state.currentGroup || ifModelsUpdated) {

      // reset scrollTop in case of changing year while animating OR stuck scrollTop
      if (ifModelsUpdated && !this.state.currentGroup && (this.scrollTimeout || (this.state.scrollToTarget === 0 && this.scrollContainer.scrollTop === 0))) {

        if (this.scrollTimeout) {
          // in case of changing year while animating
          this.scrollTimeout = window.clearTimeout(this.scrollTimeout)
          this.onScrollEnds()
        } else {
          this.setState({
            // adding 1 extra pixel in case of stuck scrollTop
            scrollToTarget: 1,
            animateScroll: false
          })
        }
      }

      // calculate group offsets when all of them are closed
      if (!this.state.currentGroup) {
        setTimeout(() => this.calculateGroupOffsets(), UI.ANIMATION_DELAY)
      }

      setTimeout(() => {
        this.setState({
          containerHeight: this.modelContainer ? this.modelContainer.offsetHeight : 0
        })

        this.scrollToOpenedGroup()
      })
    }
  }

  componentWillUnmount() {
    if (this.scrollContainer) this.scrollContainer.removeEventListener('scroll', this.handleScroll)
  }

  renderModelGroups() {
    const { currentYear, setVehicleGroup, setVehicleModel, vehicles, currentBrand } = this.props
    const { currentGroup } = this.state

    const brandedVehicles = getVehicleForBrand(vehicles, currentBrand)
    const vehiclesForYear = getVehiclesForYear(brandedVehicles, currentYear)
    const groups = getModelGroups(vehiclesForYear)

    let groupElements = []

    for (let i = 0; i < groups.length; i++) {
      if (groups[i] === currentGroup) {
        groupElements.push(<div key={i}
          className={classNames('group', groups[i] === currentGroup && 'current')}
          style={{
            'height':this.state.containerHeight,
          }}>
          <VehicleModelSelector
            currentYear={currentYear}
            currentGroup={currentGroup}
            models={getModelsOfGroup(vehiclesForYear, currentGroup)}
            setVehicleGroup={setVehicleGroup}
            setVehicleModel={setVehicleModel}
            containerRef={container => this.modelContainer = container}
          />
        </div>)
      } else {
        groupElements.push(<div key={i} className={"group"}>
          <Link to={`${ROUTE.VEHICLES}/${currentYear}/${getPathAlias(groups[i])}`}>
            {groups[i]}
          </Link></div>)
      }
    }

    return groupElements
  }

  render() {
    const { models, label = null, currentBrand } = this.props
    const { currentGroup } = this.state

    if (!models.length) {
      return <Loader type="status">{MESSAGE.EMPTY_VEHICLES}</Loader>
    }

    return <div className={classNames('VehicleGroupSelector', !!currentGroup && 'opened')}>
      {currentBrand === BRAND_NISSAN ? label : null}
      <MotionScroll animate={this.state.animateScroll} scrollTop={this.state.scrollToTarget}>
        <div className="group-container" ref={container => this.scrollContainer = container} onTouchMove={this.handleTouchMove.bind(this)} onWheel={this.handleTouchMove.bind(this)}>
          <div className="group-inner" ref={inner => this.scrollInner = inner}>
            {currentBrand === BRAND_INFINITI ? label : null}
            {this.renderModelGroups()}
          </div>
        </div>
      </MotionScroll>
    </div>
  }
}
