import React from 'react'

export function explorePickup() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78 48" style={{enableBackground:"new 0 0 78 48"}} xmlSpace="preserve">
      <g fill="none" fillRule="evenodd">
        <path className="fill" d="M2 22h32v11.344h-3.962l-1.948-4.5-4.518-1.172-3.703 4.188V34H10.88l-.806-2.891-1.252-3.437z"/>
        <g className="stroke" strokeWidth="2">
          <path d="M10.862 22c-.619.144-.854.393-.852 1.215a933.6 933.6 0 0 1 0 3.827V30.605c0 1.415.203 2.367.673 2.854.102.106.19.187.27.25.344.27.63.308 2.517.308H20.107a5.203 5.203 0 0 1-.105-.823c-.027-1.145.293-2.224.947-3.131a5.086 5.086 0 0 1 1.964-1.607 5.184 5.184 0 0 1 2.476-.444c2.54.213 4.584 2.379 4.61 4.997-.003.29-.03.574-.08.853l1.143-.018c7.276.181 13.875.258 22.25.27.514.001.927 0 1.857-.004l.556-.003c.862-.003 1.475-.003 2.078.001h.324a5.19 5.19 0 0 1-.125-.914c-.027-1.145.293-2.224.947-3.131a5.086 5.086 0 0 1 1.964-1.607 5.184 5.184 0 0 1 2.476-.444c2.54.213 4.584 2.379 4.61 4.997-.003.327-.038.65-.102.963.319-.017.584-.036.795-.057 2.022-.121 3.346-.388 4.416-.995.174-.098.271-.167.302-.203.024-.03.065-.136.112-.353l.048-.24.04-.201a18.962 18.962 0 0 0 .381-3.147 16.505 16.505 0 0 0 .007-.798 18.923 18.923 0 0 0-.135-2.04l-.039-.302-.007-.055-.002-.014a28.672 28.672 0 0 0-.096-.669 6.649 6.649 0 0 0-.233-1.04c-.1-.305-.453-.589-1.132-.9-1.257-.578-2.66-.987-4.572-1.383-.519-.107-.862-.173-1.904-.37-2.42-.457-4.881-.832-7.367-1.125l-.289-.034-.225-.183c-3.128-2.544-5.99-4.437-8.918-5.796-1.641-.762-2.588-1.121-3.596-1.298a4.13 4.13 0 0 1-.521-.131l-.113-.033a2.378 2.378 0 0 0-.14-.037c-.32-.07-.663-.13-1.092-.188a35.98 35.98 0 0 0-3.06-.278 44.866 44.866 0 0 0-3.027-.069c-.31.004-.63.01-1.047.02l-1.187.031c-.3.008-.575.014-.874.02-.813 0-1.19.258-1.422.901V22H10.862z"/>
          <path d="M21.87 36.902a5.076 5.076 0 0 1-1.868-3.689c-.027-1.146.283-2.232.943-3.146a5.096 5.096 0 0 1 1.962-1.608 5.23 5.23 0 0 1 2.474-.448c2.489.209 4.592 2.342 4.619 4.984-.026 2-1.165 3.704-2.959 4.565-1.654.795-3.773.475-5.17-.658zM59.87 36.902a5.076 5.076 0 0 1-1.868-3.689c-.027-1.146.283-2.232.943-3.146a5.096 5.096 0 0 1 1.962-1.608 5.23 5.23 0 0 1 2.474-.448c2.489.209 4.592 2.342 4.619 4.984-.026 2-1.165 3.704-2.959 4.565-1.654.795-3.773.475-5.17-.658z"/>
        </g>
        <g transform="rotate(-47 266.706 883.444)">
          <rect width="1" height="11" x="715.5" y="102.5" className="stroke" rx=".5"/>
        </g>
      </g>
    </svg>
  )
}
