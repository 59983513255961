import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'

import { UI } from '../config'

export const breakpoints = {
  desktop: `(min-device-width: ${UI.MIN_DESKTOP_WIDTH}px)`,
  //TODO: tablets Media queries
  //tablet: '(min-width: 768px) and (max-width: 1024px)',
  mobile: `(min-device-width: ${UI.MOBILE_WIDTH - 1}px) and (max-device-width: ${UI.MIN_DESKTOP_WIDTH - 1}px)`,
  mobileLandscape: `(min-device-width: ${UI.MOBILE_WIDTH - 1}px) and (max-device-width: ${UI.MIN_DESKTOP_WIDTH - 1}px) and (orientation:landscape)`,
  mobilePortrait: `(min-device-width: ${UI.MOBILE_WIDTH - 1}px) and (max-device-width: ${UI.MIN_DESKTOP_WIDTH - 1}px) and (orientation:portrait)`,
}

const { string, any } = PropTypes

export default function Media(props) {
  const breakpoint = breakpoints[props.type] || breakpoints.mobile
  return (
    <MediaQuery {...props} type={null} query={breakpoint}>
      {props.children}
    </MediaQuery>
  )
}
Media.propTypes = {
  type: string,
  children: any,
}
