import React from 'react'

export function lamp() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"  style={{enableBackground:"new 0 0 24 24"}} xmlSpace="preserve">
      <g className="inactive" stroke="none" fill="none">
        <g transform="translate(1.000000, 0.000000)">
          <polyline id="Shape" className="stroke"strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" points="14 16.5 14 22 8 22 8 16.5"/>
          <circle className="stroke"strokeWidth="2" cx="11" cy="11" r="6"/>
          <circle className="fill"cx="11" cy="1" r="1"/>
          <circle className="fill"cx="18" cy="4" r="1"/>
          <circle className="fill"cx="4" cy="4" r="1"/>
          <circle className="fill"cx="18" cy="18" r="1"/>
          <circle className="fill"cx="4" cy="18" r="1"/>
          <circle className="fill"cx="21" cy="11" r="1"/>
          <circle className="fill"cx="1" cy="11" r="1"/>
        </g>
      </g>

      <g className="active" stroke="none" fill="none" >
        <g className="fill" transform="translate(1.000000, 0.000000)">
          <circle cx="11" cy="11" r="7"/>
          <path d="M15,19.0252252 L15,22 C15,22.5522847 14.5522847,23 14,23 L8,23 C7.44771525,23 7,22.5522847 7,22 L7,19.0252252 C8.20413133,19.6265629 9.5625576,19.9648712 11,19.9648712 C12.4374424,19.9648712 13.7958687,19.6265629 15,19.0252252 Z"/>
          <circle cx="11" cy="1" r="1"/>
          <circle cx="18" cy="4" r="1"/>
          <circle cx="4" cy="4" r="1"/>
          <circle cx="18" cy="18" r="1"/>
          <circle cx="4" cy="18" r="1"/>
          <circle cx="21" cy="11" r="1"/>
          <circle cx="1" cy="11" r="1"/>
        </g>
      </g>
    </svg>
  )
}
