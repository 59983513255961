import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { convertDashesToCamelCase } from 'tw-oi-core/utils/ui'

import * as IconSet from '../svg'

export default class Icon extends Component {
  static propTypes = {
    type: PropTypes.string,
    className: PropTypes.string
  }
  static defaultProps = {
    type: ''
  }

  render() {
    const type = convertDashesToCamelCase(this.props.type)

    if (!type || !IconSet[type]) {
      return null
    }

    const icon = IconSet[type]

    return <div className={classNames('icon',`icon-${this.props.type}`, this.props['className'] && this.props.className)}>{icon()}</div>
  }
}
