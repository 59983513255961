import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as UserActions from 'tw-oi-core/actions/UserActions'

import { ScrollSink } from '../components/MotionScroll'

class ScreenContainer extends Component {

  static propTypes = {
    scrollTop: PropTypes.number,
    scrollUrl: PropTypes.string,
    currentUrl: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    UserActions: PropTypes.shape({
      setScrollState: PropTypes.func.isRequired
    }),
  }

  static defaultProps = {
    scrollTop: 0
  }

  scrollContainer = null
  scrollTimeout = null

  constructor(props) {
    super(props)

    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    if (this.scrollContainer) this.scrollContainer.addEventListener('scroll', this.handleScroll)

    const { setScrollState } = this.props.UserActions
    const { scrollUrl, currentUrl } = this.props

    //update scroll state if url changed
    if (scrollUrl !== currentUrl) {
      setScrollState(null, null)
    }
  }

  componentWillUnmount() {
    const { setScrollState } = this.props.UserActions
    const { currentUrl } = this.props

    // if still scrolling -> set immediately
    if (this.scrollTimeout) {
      window.clearTimeout(this.scrollTimeout)
      setScrollState(this.scrollContainer.scrollTop, currentUrl)
    }

    if (this.scrollContainer) this.scrollContainer.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    const { setScrollState } = this.props.UserActions
    const { currentUrl } = this.props

    //on init always firing scrollTop with 0, so ignore
    if (!this.scrollTimeout && !this.scrollContainer.scrollTop) return

    if (this.scrollTimeout) window.clearTimeout(this.scrollTimeout)

    this.scrollTimeout = window.setTimeout(() => setScrollState(this.scrollContainer.scrollTop, currentUrl), 300)
  }

  render() {
    return (
      <ScrollSink scrollTop={this.props.scrollTop}>
        <div className="screen-container" ref={container => {this.scrollContainer = container}}>
          {this.props.children}
        </div>
      </ScrollSink>
    )
  }
}

function mapStateToProps({ user }) {
  const { scrollTop, scrollUrl } = user

  return { scrollTop, scrollUrl}
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenContainer)
