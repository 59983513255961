import React from 'react'

export function sortAz() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{enableBackground:"new 0 0 24 24"}} xmlSpace="preserve">
      <g stroke="none" fill="none">
        <path className="fill" fillRule="nonzero" d="M5.69667197,6.62887919 L6.50003117,3.87268312 L7.30430121,6.62887919 L5.69667197,6.62887919 Z M7.67136896,2.78058825 C7.47189542,2.2604997 7.09389761,2 6.53464302,2 L6.46633016,2 C5.90616473,2 5.52634525,2.2604997 5.32778254,2.78058825 L3.04248977,8.93785376 C2.97326607,9.1373273 2.98875032,9.34408755 3.08803167,9.5581345 C3.18822386,9.77309229 3.34124466,9.91427219 3.54891575,9.98349589 C3.76205186,10.0527196 3.97245546,10.044522 4.18012655,9.9598141 C4.38688679,9.876017 4.5280667,9.73848044 4.6045771,9.54720444 L5.14561493,8.09988271 L7.85535825,8.09988271 L8.39548524,9.54720444 C8.47199564,9.73848044 8.61408639,9.876017 8.82084663,9.9598141 C9.02669604,10.044522 9.23801048,10.0527196 9.45205743,9.98349589 C9.65972852,9.91427219 9.81274932,9.77309229 9.91111983,9.5581345 C10.011312,9.34408755 10.0267963,9.1373273 9.95757257,8.93785376 L7.67136896,2.78058825 Z"></path>
        <path className="fill" fillRule="nonzero" d="M5.443,20.1301743 L9.135,14.9388157 C9.383,14.5554282 9.507,14.2074623 9.507,13.8949181 L9.507,13.8021967 C9.507,13.587583 9.423,13.4010984 9.256,13.2396172 C9.09,13.0791779 8.893,13 8.671,13 L4.043,13 C3.829,13 3.646,13.0833451 3.496,13.252119 C3.346,13.4208928 3.271,13.6240465 3.271,13.8636637 C3.271,14.0845283 3.348,14.2824729 3.503,14.455414 C3.657,14.6273133 3.837,14.7137839 4.043,14.7137839 L7,14.7137839 L3.361,19.9030589 C3.12,20.2687356 3,20.6167014 3,20.9469564 L3,21.0417615 C3,21.2647097 3.082,21.4543198 3.245,21.6105919 C3.407,21.766864 3.6,21.845 3.823,21.845 L8.992,21.845 C9.197,21.845 9.377,21.7574876 9.532,21.5845465 C9.686,21.4095218 9.763,21.2115771 9.763,20.9875872 C9.763,20.7469282 9.688,20.5437744 9.538,20.378126 C9.388,20.2135194 9.206,20.1301743 8.992,20.1301743 L5.443,20.1301743 Z"></path>
        <rect className="stroke" x="16.5" y="2.5" width="1" height="19" rx="0.5"></rect>
        <polyline className="stroke" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" points="13 17 17 21 21 17"></polyline>
      </g>
    </svg>
  )
}
