import React, { Component } from 'react'
import PropTypes from 'prop-types'
import createBrowserHistory from 'history/createBrowserHistory'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as UserActions from 'tw-oi-core/actions/UserActions'
import { initGoogleAnalytics, setApplicationType, trackScreenView } from 'tw-oi-core/services/analytics'
import { getPathAlias, getModelUrl, getModelGroups } from 'tw-oi-core/utils/vehicle'
import { getGaCode } from 'tw-oi-core/utils/common'

import Responsive from 'react-responsive-decorator'
import { breakpoints } from '../components/Media'

import Vehicles from './Vehicles'
import Guide from './Guide'
import Brands from './Brands'
import SunsetPromo from './SunsetPromo'
import ErrorMessage from '../components/ErrorMessage'

import {
  GA_APPLICATION_TYPE_MOBILE,
  GA_APPLICATION_TYPE_WEB,
  ROUTE,
  MESSAGE,
  VEHICLE_FAMILIES,
  BRAND_NISSAN,
} from '../config'

import { isWrapper } from '../utils/wrapper'

import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

class Main extends Component {
  static propTypes = {
    currentBrand: PropTypes.string,
    currentModel: PropTypes.string,
    currentYear: PropTypes.string,
    currentGroup: PropTypes.string,
    media: PropTypes.func.isRequired,
    promoSkipped: PropTypes.bool,
    UserActions: PropTypes.shape({
      setMedia: PropTypes.func.isRequired,
    }),
    userMedia: PropTypes.shape({
      isDesktop: PropTypes.bool,
      isLandscape: PropTypes.bool,
    }),
    acceptedConditions: PropTypes.bool,
  }

  componentDidMount() {
    this.props.media(breakpoints.desktop, () => {
      this.props.UserActions.setMedia({ isDesktop: true, isLandscape: false })
    })
    this.props.media(breakpoints.mobile, () => {
      this.props.UserActions.setMedia({ isDesktop: false, isLandscape: false })
    })
    this.props.media(breakpoints.mobileLandscape, () => {
      this.props.UserActions.setMedia({ isDesktop: false, isLandscape: true })
    })
    this.props.media(breakpoints.mobilePortrait, () => {
      this.props.UserActions.setMedia({ isDesktop: false, isLandscape: false })
    })

    // analytics
    const host = window.location.host
    initGoogleAnalytics(getGaCode(host))
    setApplicationType(isWrapper(navigator.userAgent) ? GA_APPLICATION_TYPE_MOBILE : GA_APPLICATION_TYPE_WEB)

    // Subscribe to navigation events
    const history = createBrowserHistory()
    history.listen(trackScreenView)
  }

  checkForModelFamily(year, model) {
    return year in VEHICLE_FAMILIES && typeof VEHICLE_FAMILIES[year] === 'object' && model in VEHICLE_FAMILIES[year]
  }

  render() {
    const { currentBrand, currentYear, currentModel, promoSkipped, userMedia } = this.props

    // if group isn't there but we have a model then open corresponding group
    let currentGroup = null
    if (currentModel) {
      const groups = getModelGroups([currentModel])
      currentGroup = groups ? groups[0] : null
    }

    // should wait for user media isDesktop/isLandscape set
    if (!userMedia) return null

    return (
      <Router>
        <Switch>
          {/* quick-fix for the breaking change introduced in 2.0 -> 2.1, see details in: OI-432 */}
          <Route
            path={'/guide/:year/:model/all-topics/topic/:topic'}
            render={({ match }) => {
              return (
                <Redirect
                  to={'/guide/' + match.params.year + '/' + match.params.model + '/browse/topic/' + match.params.topic}
                />
              )
            }}
          />

          <Redirect exact from={ROUTE.INDEX} to={ROUTE.GUIDE} />

          {/* Promo screen about quick guide sunset (Nissan and only in wrapper) */}
          <Route path={ROUTE.SUNSET_PROMO} component={SunsetPromo} />
          {currentBrand === BRAND_NISSAN && isWrapper(navigator.userAgent) && !promoSkipped && (
            <Route
              render={props => {
                return (
                  <Redirect
                    from={ROUTE.INDEX}
                    to={{
                      pathname: ROUTE.SUNSET_PROMO,
                      state: { referrer: props.location.pathname },
                    }}
                  />
                )
              }}
            />
          )}

          {/* redirect directly to the selected year/model (if available) */}
          {currentYear && currentModel && (
            <Redirect exact from={ROUTE.GUIDE} to={ROUTE.GUIDE + getModelUrl(currentYear, currentModel)} />
          )}
          {!currentModel && <Redirect exact from={ROUTE.GUIDE} to={ROUTE.VEHICLES} />}

          {/* redirect directly to the selected year/group if available */}
          {currentYear && currentGroup && (
            <Redirect
              exact
              from={ROUTE.VEHICLES}
              to={ROUTE.VEHICLES + '/' + currentYear + '/' + getPathAlias(currentGroup)}
            />
          )}

          <Route path={ROUTE.BRANDS} component={Brands} />
          <Route path={ROUTE.VEHICLES + '/:year?/:group?'} component={Vehicles} />

          {/* redirect to the model family if it's available for current model name */}
          <Route
            exact
            path={ROUTE.GUIDE + '/:year/:model'}
            render={props => {
              const {
                match: {
                  params: { year, model },
                },
                match,
              } = props

              if (this.checkForModelFamily(year, model)) {
                return <Redirect exact from={`${match.path}`} to={`${ROUTE.VEHICLES}/${year}/${model}`} />
              } else {
                return <Redirect exact from={match.path} to={`${match.url}${ROUTE.EXPLORE}`} />
              }
            }}
          />
          <Route path={ROUTE.GUIDE + '/:year/:model'} component={Guide} />

          <Route
            render={() => (
              <ErrorMessage
                className='inverse'
                title={MESSAGE.ERROR_NOT_FOUND}
                retryAction={ROUTE.INDEX}
                retryTitle='Visit Home Page'
                message={MESSAGE.ERROR_NOT_FOUND_MESSAGE}
              />
            )}
          />
        </Switch>
      </Router>
    )
  }
}

function mapStateToProps({ vehicle, user }) {
  const { currentBrand, currentYear, currentModel, currentGroup } = vehicle
  const { promoSkipped, media: userMedia, acceptedConditions } = user
  return { currentBrand, currentYear, currentModel, currentGroup, promoSkipped, userMedia, acceptedConditions }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Responsive(Main))
