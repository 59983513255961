import React from 'react'

export function recent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"viewBox="0 0 23 20" style={{enableBackground:"new 0 0 23 20"}} xmlSpace="preserve">
      <g className="fill" fillRule="evenodd">
        <path d="M12.851 3.634a.91.91 0 0 0-.91.91V10c0 .24.097.472.266.643l2.73 2.728a.906.906 0 0 0 1.286 0 .91.91 0 0 0 0-1.286L13.76 9.623v-5.08a.91.91 0 0 0-.909-.91"/>
        <path d="M17.27.98C12.298-1.4 6.312.707 3.93 5.68a10.058 10.058 0 0 0-.98 4.313v1.215L1.551 9.812a.91.91 0 0 0-1.286 1.286l2.95 2.948a.906.906 0 0 0 1.285 0l2.95-2.948a.91.91 0 0 0-1.287-1.286l-1.396 1.396V9.993c0-1.214.277-2.433.802-3.527C7.52 2.396 12.413.67 16.485 2.62c4.068 1.95 5.793 6.845 3.844 10.914a8.124 8.124 0 0 1-4.66 4.183 8.126 8.126 0 0 1-6.254-.338.91.91 0 0 0-.786 1.64c1.39.667 2.858.98 4.305.98 3.731 0 7.318-2.096 9.036-5.68 2.382-4.973.274-10.957-4.7-13.34"/>
      </g>
    </svg>
  )
}
