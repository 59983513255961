/*eslint no-restricted-globals: 0*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { filterTopicsByTopPicks, sortItemsByTitle } from 'tw-oi-core/utils/data'
import { trackTopPicksTopicClick } from 'tw-oi-core/services/analytics'

import { BRAND_INFINITI, MESSAGE } from '../config'

import { connect } from 'react-redux'
import ScreenHead from '../components/ScreenHead'
import ScreenContainer from '../components/ScreenContainer'
import TopicsList from '../components/TopicsList'
import { parseLocationPreviewParams } from '../utils/publication'

import '../styles/Topics.scss'
import InfinitiTopicsList from '../components/InfinitiTopicsList/InfinitiTopicsList'

class TopPicks extends Component {
  static propTypes = {
    fetching: PropTypes.bool,
    topics: ImmutablePropTypes.list,
    favorites: PropTypes.array,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    match: PropTypes.object.isRequired,
    history: PropTypes.shape({
      location: PropTypes.shape({
        search: PropTypes.string
      })
    }),
  }

  render() {
    const { topics, currentYear, currentBrand, currentModel, match, history } = this.props
    const location = history?.location
    const queryParams = parseLocationPreviewParams(location)

    // TODO: move these to selector function
    const topTics = filterTopicsByTopPicks(topics)
    const sortedTopPics = sortItemsByTitle(topTics)

    return (
      <div className='Topics'>
        <ScreenHead>
          <em>
            {currentYear} {currentBrand}
          </em>{' '}
          {currentModel}
        </ScreenHead>
        <div className='screen-content'>
          <ScreenContainer currentUrl={match.url}>
            <div className='topics'>
              <div className='topics-subtitle'>Recommended</div>
              {currentBrand === BRAND_INFINITI ? (
                <InfinitiTopicsList
                  topics={sortedTopPics}
                  emptyText={MESSAGE.EMPTY_TOPICS}
                  baseRoute={match.url}
                  className='TopPicksList'
                  onClick={topic => trackTopPicksTopicClick(topic.get('title'))}
                  isPreview={queryParams?.preview}
                />
              ) : (
                <TopicsList
                  topics={sortedTopPics}
                  emptyText={MESSAGE.EMPTY_TOPICS}
                  baseRoute={match.url}
                  className='TopPicksList'
                  onClick={topic => trackTopPicksTopicClick(topic.get('title'))}
                  isPreview={queryParams?.preview}
                />
              )}
            </div>
          </ScreenContainer>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ contents, vehicle }) {
  const { topics, fetching, currentAsset, fetchingAsset } = contents
  const { currentYear, currentModel, currentBrand } = vehicle

  return { topics, fetching, currentAsset, fetchingAsset, currentYear, currentModel, currentBrand }
}

export default connect(mapStateToProps)(TopPicks)
