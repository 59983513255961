import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { checkGlobals } from 'tw-oi-core/utils/ui'

import '../styles/Loader.scss'

export default class Loader extends Component {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    type: PropTypes.oneOf(['auto', 'web', 'status', 'android', 'ios']).isRequired,
  }

  static autoLoaderType = function () {
    if (checkGlobals(['cordova', 'device'])) {
      return window.device.platform.toLowerCase()
    } else {
      return 'web'
    }
  }

  static defaultProps = {
    //TODO: auto definition of wrapper's OS
    type: 'auto',
  }

  renderInners(length) {
    let set = []
    for (let i = 0; i < length; i++) {
      set.push(<div key={i} />)
    }

    return set
  }

  renderStatus() {
    return <div className='status'>{this.props.children}</div>
  }

  renderWebLoader() {
    return <div className='loader-default'>{this.renderInners(3)}</div>
  }

  renderAndroidLoader() {
    return (
      <div className='loader-android'>
        <svg width='1em' height='1em'>
          <circle cx='0.5em' cy='0.5em' r='0.45em' />
        </svg>
      </div>
    )
  }

  renderIosLoader() {
    return <div className='loader-ios'>{this.renderInners(12)}</div>
  }

  renderLoader(type) {
    switch (type) {
      case 'auto':
        return this.renderLoader(Loader.autoLoaderType())
      case 'status':
        return this.renderStatus()
      case 'android':
        return this.renderAndroidLoader()
      case 'ios':
        return this.renderIosLoader()
      default:
        return this.renderWebLoader()
    }
  }

  render() {
    return (
      <div {...this.props} className={['Loader', this.props.className].join(' ')}>
        {this.renderLoader(this.props.type)}
      </div>
    )
  }
}
