import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import { ROUTE, UI } from '../config'
import classNames from 'classnames'
import { getModelUrl } from 'tw-oi-core/utils/vehicle'
import { ifWrappable } from 'tw-oi-core/utils/ui'

import Icon from '../components/Icon'

import '../styles/VehicleModelSelector.scss'

export default class VehicleModelSelector extends Component {
  static propTypes = {
    currentGroup: PropTypes.string.isRequired,
    models: PropTypes.array.isRequired,
    setVehicleModel: PropTypes.func.isRequired,
    currentYear: PropTypes.string,
    containerRef: PropTypes.func.isRequired
  }

  groupModels = []

  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      animating: false
    }
  }

  componentDidMount() {

    setTimeout(() => {
      this.setState({visible: true, animating:false})

    }, UI.ANIMATION_DELAY)
  }

  render() {
    const { currentGroup, currentYear, containerRef, models } = this.props

    var buttons = []
    for (let i = 0; i < models.length; i++) {
      buttons.push(
        <li key={i} className="model">
          <Link
            to={`${ROUTE.GUIDE}${getModelUrl(currentYear,models[i])}${ROUTE.EXPLORE}`}
            className={classNames(
              "name",
              models[i].length > 15 && "name-long",
              models[i].length > 15 && !ifWrappable(models[i]) && "name-line"
            )}
            onClick={this.props.setVehicleModel && this.props.setVehicleModel(models[i])}
          >
            {models[i]}
            <Icon type="arrow-left"/>
          </Link>
        </li>
      )
    }

    return <div className={classNames("VehicleModelSelector", "visible")}>
      <div className="model-container" ref={containerRef}>
        <div className="model-group">{currentGroup}</div>
        <Link to={`${ROUTE.VEHICLES}/${currentYear}/`} className="close" disabled={this.state.animating}/>
        <ul className="model-inner">
          {buttons}
        </ul>
      </div>
    </div>
  }
}
