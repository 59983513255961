import React from 'react'

export function sortZa() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{enableBackground:"new 0 0 24 24"}} xmlSpace="preserve">
      <g stroke="none" fill="none">
        <path className="fill" fillRule="nonzero" d="M5.69667197,18.6288792 L6.50003117,15.8726831 L7.30430121,18.6288792 L5.69667197,18.6288792 Z M7.67136896,14.7805882 C7.47189542,14.2604997 7.09389761,14 6.53464302,14 L6.46633016,14 C5.90616473,14 5.52634525,14.2604997 5.32778254,14.7805882 L3.04248977,20.9378538 C2.97326607,21.1373273 2.98875032,21.3440876 3.08803167,21.5581345 C3.18822386,21.7730923 3.34124466,21.9142722 3.54891575,21.9834959 C3.76205186,22.0527196 3.97245546,22.044522 4.18012655,21.9598141 C4.38688679,21.876017 4.5280667,21.7384804 4.6045771,21.5472044 L5.14561493,20.0998827 L7.85535825,20.0998827 L8.39548524,21.5472044 C8.47199564,21.7384804 8.61408639,21.876017 8.82084663,21.9598141 C9.02669604,22.044522 9.23801048,22.0527196 9.45205743,21.9834959 C9.65972852,21.9142722 9.81274932,21.7730923 9.91111983,21.5581345 C10.011312,21.3440876 10.0267963,21.1373273 9.95757257,20.9378538 L7.67136896,14.7805882 Z"></path>
        <path className="fill" fillRule="nonzero" d="M5.443,9.13017432 L9.135,3.93881567 C9.383,3.55542815 9.507,3.20746231 9.507,2.89491814 L9.507,2.8021967 C9.507,2.58758304 9.423,2.40109835 9.256,2.2396172 C9.09,2.07917786 8.893,2 8.671,2 L4.043,2 C3.829,2 3.646,2.08334511 3.496,2.25211896 C3.346,2.42089282 3.271,2.62404653 3.271,2.86366372 C3.271,3.08452827 3.348,3.28247291 3.503,3.45541402 C3.657,3.62731331 3.837,3.71378386 4.043,3.71378386 L7,3.71378386 L3.361,8.90305889 C3.12,9.26873557 3,9.61670141 3,9.94695642 L3,10.0417615 C3,10.2647097 3.082,10.4543198 3.245,10.6105919 C3.407,10.766864 3.6,10.845 3.823,10.845 L8.992,10.845 C9.197,10.845 9.377,10.7574876 9.532,10.5845465 C9.686,10.4095218 9.763,10.2115771 9.763,9.98758716 C9.763,9.74692815 9.688,9.54377444 9.538,9.37812603 C9.388,9.21351943 9.206,9.13017432 8.992,9.13017432 L5.443,9.13017432 Z"></path>
        <rect className="stroke" x="16.5" y="2.5" width="1" height="19" rx="0.5"></rect>
        <polyline className="stroke" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" points="13 17 17 21 21 17"></polyline>
      </g>
    </svg>
  )
}
