import React from 'react'

export function noConnection() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 90" style={{enableBackground: "new 0 0 74 90"}} xmlSpace="preserve">
      <g className="fill" fillRule="nonzero">
        <path d="M2.161 90h69.677c.801 0 1.452-.65 1.452-1.452V30.484c0-.096-.009-.19-.028-.282v-.001a1.415 1.415 0 0 0-.126-.353l-.043-.082a1.42 1.42 0 0 0-.228-.308L43.833.426a1.48 1.48 0 0 0-.308-.228l-.082-.044A1.442 1.442 0 0 0 42.806 0H2.161C1.36 0 .71.65.71 1.452v87.096C.71 89.35 1.36 90 2.161 90zm66.173-60.968H44.258V4.956l24.076 24.076zM3.613 2.903h37.742v27.581c0 .802.65 1.452 1.452 1.452h27.581v55.161H3.613V2.903z"/>
        <path d="M37.229 40.587h-.232c-4.727 0-9.028 1.893-12.182 4.964l-.01.005-.005.006c-3.202 3.123-5.22 7.463-5.283 12.278-.125 9.64 7.616 17.585 17.254 17.712h.231c4.717 0 9.011-1.887 12.165-4.948l.025-.017.011-.016c3.2-3.124 5.217-7.461 5.28-12.274.125-9.638-7.616-17.584-17.254-17.71zM37 72.374h-.188c-7.886-.105-14.219-6.604-14.117-14.492a14.221 14.221 0 0 1 3.252-8.876l19.82 20.345A14.221 14.221 0 0 1 37 72.374zm11.047-5.236l-19.82-20.346a14.22 14.22 0 0 1 8.769-3.026l.19.001c7.886.104 14.219 6.604 14.117 14.491a14.233 14.233 0 0 1-3.256 8.88z"/>
      </g>
    </svg>
  )
}
