import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { BRAND_INFINITI, BRAND_NISSAN } from '../config'

export default class TermsContent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      overlayBottom: 0,
      containerHeight: 0,
      innerHeight: 0,
      overlayHeight: 0,
    }

    this.handleScroll = this.handleScroll.bind(this)
  }

  static propTypes = {
    currentBrand: PropTypes.string.isRequired,
  }

  scrollContainer = null
  scrollInner = null
  scrollOverlay = null

  handleScroll() {
    const { containerHeight, innerHeight, overlayHeight } = this.state
    const { scrollTop } = this.scrollContainer

    let bottom = scrollTop + containerHeight + overlayHeight - innerHeight

    bottom = bottom > 0 ? bottom : 0

    this.setState({ overlayBottom: bottom })
  }

  renderText(brand) {
    const year = new Date().getFullYear().toString()
    switch (brand) {
      case BRAND_NISSAN:
        return (
          <div ref={inner => (this.scrollInner = inner)}>
            <p>
              Do not use this feature while operating a motor vehicle. Using this system while driving can distract the
              driver and may cause loss of vehicle control resulting in an accident, serious injury, or death.
            </p>
            <p>
              This easy-to-use Electronic Quick Reference Guide is intended to help you more fully appreciate some of
              the unique features and functions of your new vehicle. However, it is not intended to replace your
              Owner&lsquo;s Manual. You should read your Owner&lsquo;s Manual because it contains more detailed
              information to help you better understand the important safety warnings, features, operation and
              maintenance of your vehicle. Failure to follow the warnings, cautions and instructions in the Owner’s
              Manual for your vehicle can result in an accident, serious injury or death. All information,
              specifications, photographs and illustrations in this publication are those in effect at the time of
              release. Nissan North America, Inc. reserves the right to change materials, specifications, or design, at
              any time, without notice and without obligation. This does not constitute an offer, representation or
              warranty (express or implied) by Nissan North America, Inc.
            </p>
            <p>
              Interested parties should confirm the accuracy of any information in this Guide as it relates to a vehicle
              directly with Nissan North America, Inc. before relying on it to make a purchase decision. See your Nissan
              dealer or contact Nissan North America, Inc. for complete details on feature/service availability, terms,
              conditions, restrictions, and limitations.
            </p>
            <p>
              Nissan names, logos, product names, feature names, and slogans are trademarks owned by or licensed to
              Nissan Motor Co. Ltd., and/or its North American subsidiaries. Other trademarks and trade names are those
              of their respective owners. &copy;{year} Nissan
            </p>
            <p>
              All videos are copyrighted material of Nissan North America, Inc. and should not be copied, edited, or
              reproduced without the permission of Nissan.
            </p>
          </div>
        )
      case BRAND_INFINITI:
        return (
          <div ref={inner => (this.scrollInner = inner)}>
            <p>
              Do not use this feature while operating a motor vehicle. Using this system while driving can distract the
              driver and may cause loss of vehicle control resulting in an accident, serious injury, or death.
            </p>
            <p>
              This easy-to-use Electronic Quick Reference Guide is intended to help you more fully appreciate some of
              the unique features and functions of your new vehicle. However, it is not intended to replace your
              Owner&lsquo;s Manual. You should read your Owner&lsquo;s Manual because it contains more detailed
              information to help you better understand the important safety warnings, features, operation and
              maintenance of your vehicle. Failure to follow the warnings, cautions and instructions in the Owner’s
              Manual for your vehicle can result in an accident, serious injury or death. All information,
              specifications, photographs and illustrations in this publication are those in effect at the time of
              release. Nissan North America, Inc. reserves the right to change materials, specifications, or design, at
              any time, without notice and without obligation. This does not constitute an offer, representation or
              warranty (express or implied) by Nissan North America, Inc.
            </p>
            <p>
              Interested parties should confirm the accuracy of any information in this Guide as it relates to a vehicle
              directly with Nissan North America, Inc. before relying on it to make a purchase decision. See your
              INFINITI retailer or contact Nissan North America, Inc. for complete details on feature/service
              availability, terms, conditions, restrictions, and limitations.
            </p>
            <p>
              INFINITI names, logos, product names, feature names, and slogans are trademarks owned by or licensed to
              Nissan Motor Co. Ltd., and/or its North American subsidiaries. Other trademarks and trade names are those
              of their respective owners. &copy;{year} INFINITI
            </p>
            <p>
              All videos are copyrighted material of Nissan North America, Inc. and should not be copied, edited, or
              reproduced without the permission of Nissan
            </p>
          </div>
        )
      default:
        return (
          <div ref={inner => (this.scrollInner = inner)}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam consectetur, massa sed vehicula ullamcorper,
              sem mauris interdum nisi, vel dictum mi ante eget quam. Suspendisse tincidunt ornare purus egestas
              suscipit. Quisque dignissim tristique augue, in volutpat enim. Nulla iaculis lacus a nisi pharetra
              malesuada. Nulla vel facilisis felis. Suspendisse in est a metus scelerisque finibus ut eget metus. Donec
              ultrices dolor nec neque dictum, quis porta est semper. Interdum et malesuada fames ac ante ipsum primis
              in faucibus. Duis pharetra cursus tellus at consectetur. Donec mollis magna quis enim vulputate aliquam.
              Sed eget ex et sapien mollis sagittis eu non urna. Donec dignissim elit consectetur tellus porttitor, in
              fringilla risus sollicitudin. Integer nulla dui, viverra quis diam sit amet, venenatis condimentum nibh.
              Aliquam quis commodo risus. Nullam ultricies, lectus nec elementum imperdiet, urna purus hendrerit mauris,
              sit amet bibendum risus magna nec ex.
            </p>
            <p>
              Etiam eget mollis tortor. Nunc eget fringilla lacus, a laoreet tellus. Fusce lobortis purus in ullamcorper
              accumsan. Ut vel urna at ex sodales fringilla at tempor lorem. Integer elit nunc, pretium quis nibh ac,
              accumsan posuere ipsum. Vivamus et nulla quis velit sagittis blandit sit amet sed mi. Vivamus quis tortor
              eu sem vehicula scelerisque. Vestibulum nec efficitur eros. Sed ut sem ut magna volutpat rhoncus quis nec
              diam. Praesent viverra sem lectus, eget vulputate elit venenatis quis. Ut dignissim facilisis aliquam. Sed
              finibus blandit mollis. Etiam augue ante, sodales eu tincidunt eu, pretium sed nulla. Maecenas at viverra
              tortor. Phasellus blandit dignissim sem vel volutpat.
            </p>
            <p>
              Nam eros nibh, auctor id ligula at, maximus tincidunt sapien. Quisque sollicitudin mauris sed diam lacinia
              convallis. Sed dapibus, neque sed cursus rutrum, leo diam ultricies mi, id tempus risus augue non nulla.
              Vestibulum mollis nec massa dictum bibendum. Ut euismod sem sed orci molestie, eu rhoncus quam pharetra.
              Phasellus nec aliquam odio, ut tristique neque. Vestibulum tempor ante ut ipsum luctus auctor. Nulla
              facilisi. Sed sit amet metus erat. Fusce non pretium lacus. Phasellus auctor mauris enim, vel feugiat urna
              elementum quis. Nulla sodales, ex eget lobortis sodales, magna nisi malesuada eros, eu accumsan nibh risus
              vitae mi. Vestibulum lobortis mollis nisl at congue. Etiam aliquam porttitor lectus sit amet vulputate.
              Praesent elementum scelerisque ultricies. Sed non placerat diam.
            </p>
            <p>
              Vestibulum sollicitudin, arcu venenatis commodo sagittis, arcu nulla euismod libero, lobortis commodo
              turpis augue at justo. In hac habitasse platea dictumst. Integer interdum faucibus sapien non condimentum.
              Phasellus at felis facilisis, imperdiet libero at, venenatis magna. Maecenas condimentum fringilla lorem
              vitae maximus. Vestibulum non orci ac odio lacinia cursus euismod at lectus. Curabitur lobortis eleifend
              viverra. Fusce rutrum enim ut dolor iaculis molestie. Nullam mattis, est non venenatis consectetur, nisl
              nibh gravida mi, sed tincidunt ex enim quis enim. Vivamus at hendrerit sapien. Aliquam ultrices, sapien in
              cursus vehicula, dui libero molestie massa, vitae dictum eros lectus et dolor.
            </p>
          </div>
        )
    }
  }

  componentDidMount() {
    if (this.scrollContainer && this.scrollInner && this.scrollOverlay) {
      this.scrollContainer.addEventListener('scroll', this.handleScroll)
      this.setState({
        containerHeight: this.scrollContainer.offsetHeight,
        innerHeight: this.scrollInner.offsetHeight,
        overlayHeight: this.scrollOverlay.offsetHeight,
      })
    }
  }

  componentWillUnmount() {
    if (this.scrollContainer) this.scrollContainer.removeEventListener('scroll', this.handleScroll)
  }

  render() {
    const { currentBrand } = this.props

    return (
      <div className='terms-content'>
        <div className='terms-container screen-text' ref={container => (this.scrollContainer = container)}>
          {this.renderText(currentBrand)}
        </div>
        <div
          className='overlay'
          ref={overlay => (this.scrollOverlay = overlay)}
          style={{ transform: `translateY(${this.state.overlayBottom}px)` }}
        />
      </div>
    )
  }
}
