import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ScreenHead from './ScreenHead'
import ErrorMessage from './ErrorMessage'

import { MESSAGE, ROUTE } from '../config'

class ComingSoon extends Component {

  static propTypes = {
    currentBrand: PropTypes.string.isRequired,
    currentYear: PropTypes.string.isRequired,
    currentModel: PropTypes.string.isRequired,
  }

  render() {
    const { currentYear, currentModel, currentBrand } = this.props
    return (
      <div className="Topics">
        <ScreenHead><em>{currentYear} {currentBrand}</em> {currentModel}</ScreenHead>
        <div className="screen-content">
          <ErrorMessage className="inverse" title={MESSAGE.COMING_SOON} retryAction={ROUTE.VEHICLES} retryTitle="Select vehicle" message={MESSAGE.ERROR_VEHICLE_RETRY}/>
        </div>
      </div>
    )
  }
}

function mapStateToProps({vehicle}) {
  const { currentYear, currentModel, currentBrand } = vehicle

  return { currentYear, currentModel, currentBrand }
}

export default connect(mapStateToProps)(ComingSoon)
