import React from 'react'

export function binocular() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" style={{enableBackground:"new 0 0 24 24"}} xmlSpace="preserve">
      <g className="inactive"  stroke="none" fill="none">
        <rect className="fill" x="9.25" y="15" width="5.5" height="2"/>
        <polygon className="fill" points="9.25 6 14.75 6 14.75 8 9.25 8"/>
        <ellipse className="stroke" strokeWidth="1.81069959" cx="5.30041152" cy="16.7942708" rx="4.30041152" ry="4.20572917"/>
        <path className="stroke"  strokeWidth="1.81069959" d="M9.51028807,15.6879611 C9.51028807,10.5509542 9.51028807,7.51014762 9.51028807,6.56554137 C9.51028807,5.148632 8.77801293,4 7.42798354,4 C6.38520794,4 5.49493608,4.68529282 5.14371441,5.65018262 C5.07472278,5.83971912 3.84437627,8.96376688 1.4526749,15.0223259"/>
        <path className="stroke"  strokeWidth="1.81069959" d="M22.5473251,15.6879611 C22.5473251,10.5509542 22.5473251,7.51014762 22.5473251,6.56554137 C22.5473251,5.148632 21.81505,4 20.4650206,4 C19.422245,4 18.5319731,4.68529282 18.1807515,5.65018262 C18.1117598,5.83971912 16.8814133,8.96376688 14.4897119,15.0223259" transform="translate(18.518519, 9.843981) scale(-1, 1) translate(-18.518519, -9.843981) "/>
        <ellipse className="stroke" strokeWidth="1.81069959" cx="18.6995885" cy="16.7942708" rx="4.30041152" ry="4.20572917"/>
      </g>
      <g className="active">
        <path className="fill" d="M13.8034558,17 L10.2610603,17 C10.2057979,19.845659 7.93264297,22.1350994 5.1365627,22.1350994 C2.30581228,22.1350994 0.0110356537,19.7885309 0.0110356537,16.893892 L0.0204016236,16.893892 C0.0204016236,13.9890621 2.31548445,11.6421805 5.1365627,11.6421805 C7.05418849,11.6421805 8.78539247,12.7347984 9.66572039,14.4487925 C9.70647692,14.5281453 9.71725819,14.5496156 9.71913417,14.5534829 C9.72301804,14.5639448 9.75465458,14.6496905 9.77749352,14.7094505 L10.1203131,14.5784324 C10.1226832,14.7173468 10.1250882,14.8578694 10.1275283,15 L14,15 L14,14.6448338 L14.1690748,14.7094505 C14.1919137,14.6496905 14.2235503,14.5639448 14.2274341,14.5534829 C14.2293101,14.5496156 14.2400914,14.5281453 14.2808479,14.4487925 C15.1611758,12.7347984 16.8923798,11.6421805 18.8100056,11.6421805 C21.6310838,11.6421805 23.9261667,13.9890621 23.9261667,16.893892 L24.0534805,16.893892 C24.0534805,19.7885309 21.7587039,22.1350994 18.9279534,22.1350994 C16.1318732,22.1350994 13.8587182,19.845659 13.8034558,17 Z M21.9851451,10.6505069 C21.035202,10.1471961 19.9555541,9.8626462 18.8100056,9.8626462 C16.9700062,9.8626462 15.2601019,10.6038997 14,11.8545394 L14,8 L10.0179038,8 C10.0307442,9.01202995 10.0504914,10.3412461 10.0771452,11.9876485 C8.8059383,10.6566676 7.04092865,9.8626462 5.1365627,9.8626462 C4.01856992,9.8626462 2.96334534,10.1336713 2.03019887,10.6145668 C3.28310316,7.03762009 3.93970354,5.16609784 4,5 C4.44086293,3.78556089 5.6910799,3 7,3 C8.69459337,3 10,4.21663557 10,6 L14,6 C14,4.21663557 15.3054066,3 17,3 C18.3089201,3 19.5591371,3.78556089 20,5 C20.0604981,5.16665325 20.7222131,7.05015554 21.9851451,10.6505069 Z"/>
      </g>
    </svg>
  )
}
