import React from 'react'

export function exploreRearInfiniti() {
  return (
    <svg
      style={{ enableBackground: 'new 0 0 918 378' }}
      viewBox='0 0 918 378'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M917.213 190.042C916.751 159.787 914.202 136.633 899.899 118.269C882.092 95.3894 850.925 86.0582 792.341 86.0582C784.497 86.0582 771.193 85.9768 762.627 85.9226L754.564 85.8819C687.878 85.8819 620.37 57.4416 601.267 48.7207C596.453 46.5234 591.631 44.3538 586.729 42.3532C434.857 -19.3898 285.94 -1.82654 222.895 25.0544C82.9502 84.7222 1.47497 85.8751 0.667969 85.8819L0.728966 127.546V231.712L84.0488 315.033H107.629C116.918 350.916 149.472 377.529 188.215 377.529C226.961 377.529 259.515 350.916 268.804 315.033H649.258C658.549 350.916 691.101 377.529 729.846 377.529C768.592 377.529 801.144 350.916 810.435 315.033H834.006C867.499 315.033 917.334 292.845 917.334 231.705V190.042L917.213 190.042ZM188.215 335.865C165.241 335.865 146.552 317.176 146.552 294.202C146.552 271.227 165.241 252.538 188.215 252.538C211.192 252.538 229.88 271.227 229.88 294.202C229.88 317.176 211.192 335.865 188.215 335.865ZM729.846 335.865C706.872 335.865 688.183 317.176 688.183 294.202C688.183 271.227 706.872 252.538 729.846 252.538C752.821 252.538 771.511 271.227 771.511 294.202C771.511 317.176 752.821 335.865 729.846 335.865ZM875.67 231.705C875.67 269.755 843.662 273.214 833.85 273.37H810.435C801.144 237.486 768.592 210.874 729.846 210.874C691.101 210.874 658.549 237.486 649.258 273.37H399.506V43.0271C452.204 45.8284 511.529 56.7571 571.03 80.9453C575.397 82.7221 579.69 84.6752 583.976 86.6279C605.004 96.2234 679.475 127.546 754.564 127.546L762.376 127.587C771.016 127.641 784.429 127.722 792.341 127.722C834.725 127.722 858.453 132.849 867.025 143.861C875.649 154.935 875.649 178.385 875.649 210.874H875.669L875.67 231.705Z'
        fill='#000'
        className='fill'
      />
    </svg>
  )
}
