import React from 'react'

export function exploreFrontInfiniti() {
  return (
    <svg
      viewBox='0 0 918 378'
      style={{ enableBackground: 'new 0 0 918 378' }}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M917.213 190.042C916.751 159.787 914.202 136.633 899.899 118.269C882.092 95.3894 850.925 86.0582 792.341 86.0582C784.497 86.0582 771.193 85.9768 762.627 85.9226L754.564 85.8819C687.878 85.8819 620.37 57.4416 601.267 48.7207C596.453 46.5234 591.631 44.3538 586.729 42.3532C434.857 -19.3898 285.94 -1.82654 222.895 25.0544C82.9502 84.7222 1.47497 85.8751 0.667969 85.8819L0.728966 127.546V231.712L84.0488 315.033H107.629C116.918 350.916 149.472 377.529 188.215 377.529C226.961 377.529 259.515 350.916 268.804 315.033H649.258C658.549 350.916 691.101 377.529 729.846 377.529C768.592 377.529 801.144 350.916 810.435 315.033H834.006C867.499 315.033 917.334 292.845 917.334 231.705V190.042L917.213 190.042ZM188.215 335.865C165.241 335.865 146.552 317.176 146.552 294.202C146.552 271.227 165.241 252.538 188.215 252.538C211.192 252.538 229.88 271.227 229.88 294.202C229.88 317.176 211.192 335.865 188.215 335.865ZM188.215 210.874C149.472 210.874 116.918 237.486 107.629 273.37H42.3923V123.375C82.1296 117.181 149.183 101.774 239.237 63.3821C284.866 43.9284 389.309 29.7389 503.784 58.8912V273.37H268.804C259.515 237.486 226.961 210.874 188.215 210.874ZM729.846 335.865C706.872 335.865 688.183 317.176 688.183 294.202C688.183 271.227 706.872 252.538 729.846 252.538C752.821 252.538 771.511 271.227 771.511 294.202C771.511 317.176 752.821 335.865 729.846 335.865Z'
        fill='#000'
        className='fill'
      />
    </svg>
  )
}
