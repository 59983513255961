import React, { PureComponent, createRef } from 'react'
import { pdfjs } from 'react-pdf'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { checkIsPreviewMode } from '../../utils/common'
import Loader from '../Loader'
import PdfPreview from '../PdfPreview'
import Media from '../Media'
import Icon from '../Icon'

import { PlusIcon, MinusIcon, OpenIcon } from './icons'
import SearchResults from './SearchResults'
import RoundBtn from './RoundBtn'
import { extractHightlightWords } from './utils'

import './SearchResults/SearchResults.scss'
import './PdfViewer.scss'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

class PdfViewer extends PureComponent {
  static propTypes = {
    file: PropTypes.string,
    searchPagesByPublication: PropTypes.object,
    selectedPageIndex: PropTypes.number,
    onSearchResultSelect: PropTypes.func,
    selectedSearchResult: PropTypes.shape({
      searchHighlights: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    hasPagesPreview: PropTypes.bool,
    pages: PropTypes.arrayOf(PropTypes.object),
    baseRoute: PropTypes.string,
    match: PropTypes.object,
    publication: PropTypes.object,
    history: PropTypes.shape({
      location: PropTypes.object,
    })
  }

  constructor(props) {
    super(props)

    this.state = {
      viewerContainerHeight: null,
      hasPagesPreview: false,
      isPagesPreviewOpen: false,
      scale: 1.5,
    }

    this.viewerContainer = createRef()
  }

  componentDidMount() {
    this.setState({
      viewerContainerHeight: this.viewerContainer.current.scrollHeight
    })
  }

  handleZoomIn = () => {
    if (this.state.scale < 5) {
      this.setState({ scale: this.state.scale + 0.1 })
    }
  }

  handleZoomOut = () => {
    if (this.state.scale > 0.5) {
      this.setState({ scale: this.state.scale - 0.1 })
    }
  }

  handleTogglePagesPreviewOpen = () =>
    this.setState({ isPagesPreviewOpen: !this.state.isPagesPreviewOpen })

  handleSearchResultSelect = (searchResult) => {
    this.props.onSearchResultSelect(searchResult)
    this.setState({ isPagesPreviewOpen: false })
  }

  render() {
    const {
      file,
      pages,
      selectedPageIndex,
      selectedSearchResult,
      hasPagesPreview,
      baseRoute,
      match,
      publication,
      history: { location }
    } = this.props
    const {
      scale,
      isPagesPreviewOpen,
      viewerContainerHeight,
    } = this.state
    const isPreviewMode = checkIsPreviewMode(location)
    let backLabel = match.params.referrer.substring(1)
    if (backLabel === 'browse') {
      backLabel = 'Manuals'
    }

    return (
      <div className="PdfViewer" ref={this.viewerContainer}>
        <Media type="desktop">
          <ul className="topics-breadcrumb">
            {!isPreviewMode && (
              <li>
                <Link to={`${baseRoute}${match.params.referrer}`}>{backLabel}</Link>
                <Icon type="arrow-left" />
              </li>
            )}
            <li>
              {publication.get('title')}
            </li>
          </ul>
        </Media>

        <header className="PdfViewer__actions">
          <div className="PdfViewer__scale">
            <RoundBtn
              className="PdfViewer__scale-btn"
              onClick={this.handleZoomIn}
            >
              <PlusIcon />
            </RoundBtn>
            <RoundBtn
              className="PdfViewer__scale-btn"
              onClick={this.handleZoomOut}
            >
              <MinusIcon />
            </RoundBtn>
          </div>

          {hasPagesPreview && <RoundBtn
            className="PdfViewer__list-btn"
            onClick={this.handleTogglePagesPreviewOpen}
          >
            <OpenIcon />
          </RoundBtn>}
        </header>

        {hasPagesPreview && <SearchResults
          isOpen={isPagesPreviewOpen}
          items={pages}
          selectedItem={selectedSearchResult}
          onSelect={this.handleSearchResultSelect}
          onClose={this.handleTogglePagesPreviewOpen}
        />}

        <PdfPreview
          file={file}
          loader={<Loader />}
          scale={scale}
          viewerContainerHeight={viewerContainerHeight}
          selectedPageIndex={selectedPageIndex}
          highlightWords={selectedSearchResult ? extractHightlightWords(selectedSearchResult.searchHighlights) : []}
          onInitPageSizeRetrieved={({ width }) => {
            const scale = (window.innerWidth - 16) / width
            if (scale < 1) {
              this.setState({ scale })
            }
          }}
        />
      </div>
    )
  }
}

export default PdfViewer
