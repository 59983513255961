import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Loader from "./Loader"
import { Link } from 'react-router-dom'
import Icon from '../components/Icon'
import { getPathAlias, getModelUrl } from 'tw-oi-core/utils/vehicle'
import { ifWrappable } from 'tw-oi-core/utils/ui'

import classNames from 'classnames'
import { MESSAGE, ROUTE, } from '../config'

import '../styles/VehicleModelFamily.scss'

export default class VehicleModelFamily extends Component {

  static propTypes = {
    models: PropTypes.array,
    currentYear: PropTypes.string.isRequired,
    familyGroup: PropTypes.string.isRequired,
    setVehicleModel: PropTypes.func.isRequired,
    setVehicleGroup: PropTypes.func.isRequired,
    label: PropTypes.string,
  }

  onCloseBtnClick() {
    this.props.setVehicleGroup(null)
  }

  render() {
    const { currentYear, familyGroup, models, label = null } = this.props

    if (!models.length) {
      return <Loader type="status">{MESSAGE.EMPTY_VEHICLES}</Loader>
    }

    var buttons = []
    for (let i = 0; i < models.length; i++) {
      buttons.push(
        <li key={i} className="model">
          <Link
            to={`${ROUTE.GUIDE}${getModelUrl(currentYear,models[i])}${ROUTE.EXPLORE}`}
            className={classNames(
              "name",
              models[i].length > 15 && "name-long",
              models[i].length > 15 && !ifWrappable(models[i]) && "name-line"
            )}
            onClick={this.props.setVehicleModel && this.props.setVehicleModel(models[i])}
          >
            {models[i]}
            <Icon type="arrow-left"/>
          </Link>
        </li>
      )
    }

    return <div className="VehicleModelFamily">
      <div className="group-container">
        <div className="group-inner">
          {label}
          <div className="group">
            <div className="model-container">
              <Link className="model-group" to={`${ROUTE.VEHICLES}/${currentYear}/${getPathAlias(familyGroup)}`}>
                <div className="back"><Icon type="arrow-left"/></div>
                {familyGroup}
              </Link>

              <ul className="model-inner">
                {buttons}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}
