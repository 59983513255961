import React from 'react'

export function book() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" style={{enableBackground:"new 0 0 24 24"}} xmlSpace="preserve">
      <g className="inactive"  stroke="none" fill="none">
        <polygon className="stroke" strokeWidth="2" points="2 2.52747253 2 18.5274725 12 21 22 18.5274725 22 2.52747253 12 5"/>
        <path d="M12,6 L12,20.5274725" className="stroke" strokeWidth="2"/>
        <path d="M16,5 L16,8" className="stroke" strokeWidth="2"/>
      </g>
      <g className="active">
        <path className="fill" d="M13,3.75025008 L15,3.25074958 L15,8 C15,8.55228475 15.4477153,9 16,9 C16.5522847,9 17,8.55228475 17,8 L17,8 L17,3 C17,2.91675821 16.9898291,2.83589196 16.9706623,2.75857618 L21.8126937,1.54927764 C21.8919426,1.52948518 21.9733181,1.51947712 22.0550013,1.51947712 C22.607286,1.51947716 23.0550013,1.96719245 23.0550012,2.5194772 L23.0550012,2.5194772 L23.0550001,18.4080454 C23.055,18.8670007 22.7425858,19.2670365 22.2973076,19.3782448 L13,21.7002498 L13,3.75025008 Z M11,3.75024942 L2.18730878,1.54927759 L2.18730878,1.54927759 C1.6514824,1.41545482 1.1086245,1.74134313 0.97480173,2.27716951 C0.95500929,2.35641844 0.945001223,2.43779387 0.945001217,2.519477 L0.945001217,2.519477 L0.945000057,18.4080453 L0.945000057,18.4080453 C0.945000024,18.8670006 1.25741429,19.2670365 1.70269249,19.3782449 L11,21.7002498 L11,3.75024942 Z"/>
      </g>
    </svg>
  )
}
