import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getConfig } from 'tw-oi-core'
import { ROUTE, UI, MESSAGE, BRAND_NISSAN } from '../config'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as UserActions from 'tw-oi-core/actions/UserActions'
import * as ContentsActions from 'tw-oi-core/actions/ContentsActions'
import * as ModalActions from 'tw-oi-core/actions/ModalActions'

import { checkIsPreviewMode } from '../utils/common'
import TopMenu from './TopMenu'
import Media from './Media'
import Icon from './Icon'
import TermsOfServiceModal from './TermsOfServiceModal'

class ScreenHead extends Component {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    back: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    backTitle: PropTypes.string,
    location: PropTypes.object.isRequired,
    menuEnabled: PropTypes.bool,
    acceptedConditions: PropTypes.bool,
    openedModals: PropTypes.array,
    currentBrand: PropTypes.string,
    UserActions: PropTypes.shape({
      acceptConditions: PropTypes.func.isRequired,
    }).isRequired,
    ModalActions:PropTypes.shape({
      openModal: PropTypes.func.isRequired,
      closeModal: PropTypes.func.isRequired,
    }).isRequired,
    ContentsActions: PropTypes.shape({
      setSearchQuery: PropTypes.func.isRequired
    }).isRequired,
    landscapeOverlay: PropTypes.bool,
    userMedia: PropTypes.shape({
      isDesktop: PropTypes.bool,
      isLandscape: PropTypes.bool
    }),
  }
  static defaultProps = {
    title: "",
    back: null,
    backTitle: "Back",
    menuEnabled: true,
    landscapeOverlay: true,
  }

  terms = null

  render() {
    const { FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW } = getConfig()
    const {
      back, backTitle, location, menuEnabled, openedModals, acceptedConditions,
      currentBrand, userMedia, landscapeOverlay,
    } = this.props
    const { acceptConditions } = this.props.UserActions
    const { openModal, closeModal } = this.props.ModalActions
    const isPreviewMode = checkIsPreviewMode(location)

    let backLink = null

    if (back) {
      if (typeof back === 'function') {
        backLink = <button onClick={back}><Icon type="arrow-left"/>{backTitle}</button>
      } else {
        backLink = <Link to={back} replace><Icon type="arrow-left"/>{backTitle}</Link>
      }
    }

    const recallsLinkURL = currentBrand === BRAND_NISSAN ? 'https://www.nissanusa.com/recalls-vin.html' : 'https://www.infinitiusa.com/recalls-vin.html'

    const title = this.props.children || this.props.title
    const showRotationWarning = userMedia.isLandscape && (acceptedConditions && !openedModals.includes(UI.MODAL_ID_TERMS)) && landscapeOverlay
    const menuLinks = menuEnabled ? [
      ...(location.pathname && location.pathname.indexOf(ROUTE.VEHICLES) === 0 ? [] : [{url: ROUTE.VEHICLES, title: 'Select Vehicle'}]),
      {onClick: () => window.open(recallsLinkURL, '_blank'), title: 'Recall Information'},
      {onClick: () => {openModal(UI.MODAL_ID_TERMS)}, title: 'Terms of Service'},
    ] : []

    //TODO: refactor Media conditional rendering -> for using redux condition state isDesktop
    return (
      <div className={classNames("screen-head", isPreviewMode && 'screen-head--preview')}>
        <TermsOfServiceModal
          isOpen={openedModals.includes(UI.MODAL_ID_TERMS) || !acceptedConditions}
          acceptedConditions={acceptedConditions}
          handleCloseModal={() => {
            if (!acceptedConditions) acceptConditions()
            closeModal(UI.MODAL_ID_TERMS)
          }}
          handleOpenModal={() => {
            openModal(UI.MODAL_ID_TERMS)
          }}
          currentBrand={currentBrand}
        />

        { showRotationWarning &&
        <div className={classNames("screen-overlay")}>
          <Icon type="rotate-device"/>
          <div className="overlay-text">{MESSAGE.ROTATE_DEVICE_ORIENTATION}</div>
        </div>}

        <Media type="desktop">
          {isPreviewMode ? <span className="screen-logo" /> : <Link to={ROUTE.INDEX} className="screen-logo" title="Logo" />}
        </Media>
        <Media type="desktop">
          <h1 className="screen-title">{title}</h1>
        </Media>

        <Media type="mobile">
          {back ?
            <div className={classNames(
              "screen-back",
              backTitle.length > 25 && "title-long",
            )}>{backLink}</div>  :
            <Link to={ROUTE.GUIDE} className="screen-title">{title}</Link>}
        </Media>

        {!isPreviewMode && (
          <TopMenu
            currentBrand={currentBrand}
            links={menuLinks}
          />
        )}

        {FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW && <div className='content-preview-mode'>Draft content preview</div>}
      </div>
    )
  }
}


function mapStateToProps({user, vehicle, modal}) {
  return {
    acceptedConditions: user.acceptedConditions,
    openedModals: modal.openedModals,
    userMedia: user.media,
    currentBrand: vehicle.currentBrand,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
    ContentsActions: bindActionCreators(ContentsActions, dispatch),
    ModalActions: bindActionCreators(ModalActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScreenHead))
