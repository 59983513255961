import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import onClickOutside from 'react-onclickoutside'

import Icon from './Icon'
import { BRAND_NISSAN } from '../config'

class TopMenu extends Component {
  static propTypes = {
    links: PropTypes.array.isRequired,
    currentBrand: PropTypes.string
  }
  static defaultProps = {
    links: [],
    currentBrand: BRAND_NISSAN
  }

  constructor(props) {
    super(props)

    this.state = {
      opened: false
    }
  }

  handleTriggerClick = () => {
    this.setState({ opened: !this.state.opened })
  }

  // Method will be called by onClickOutside HOC
  handleClickOutside = () => {
    this.setState({ opened: false })
  }

  render() {
    const { links, currentBrand } = this.props

    if (!links.length) return null

    return (
      <div className={classNames("screen-topmenu", this.state.opened && "opened")}>
        <button title='topmenu-trigger' className="topmenu-trigger" onClick={this.handleTriggerClick}><Icon type="dots"/></button>
        <ul className="topmenu-inner">
          {this.props.links.map((link, i) => {
            if (link.url) return <li key={i}><Link className="topmenu-link" to={link.url}>{link.title}</Link></li>

            return <li key={i}><button className="topmenu-link" onClick={link.onClick ? link.onClick : null}>{link.title}</button></li>
          })}
          <li>
            <a className="topmenu-link"
              target="_blank"
              rel="noopener noreferrer"
              href={currentBrand === BRAND_NISSAN ? "https://www.nissanusa.com/privacy.html" : "https://www.infinitiusa.com/privacy.html"}>
                Privacy Policy
            </a>
          </li>
          <li>
            <a className="topmenu-link"
              target="_blank"
              rel="noopener noreferrer"
              href={currentBrand === BRAND_NISSAN ? "https://www.nissanusa.com/data-privacy.html" : "https://www.infinitiusa.com/data-privacy.html"}>
                Do Not Sell My Personal Information
            </a>
          </li>
        </ul>
      </div>
    )
  }
}


export default onClickOutside(TopMenu)
