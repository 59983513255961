import React from 'react'

export function dots() {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" style={{enableBackground:"new 0 0 512 512"}} xmlSpace="preserve">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-320.000000, -43.000000)">
          <g className="fill" transform="translate(330.000000, 46.000000)">
            <rect x="0" y="0" width="4" height="4"/>
            <rect x="0" y="7" width="4" height="4"/>
            <rect x="0" y="14" width="4" height="4"/>
          </g>
        </g>
      </g>
    </svg>
  )
}
