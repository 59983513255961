/* eslint react/no-find-dom-node: "off" */
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Motion, spring } from 'react-motion'
import PropTypes from 'prop-types'

export class ScrollSink extends Component {

  static propTypes = {
    scrollTop: PropTypes.number,
    scrollLeft: PropTypes.number,
    children: PropTypes.any.isRequired,
  }

  componentDidMount() {
    if (this.props.scrollTop) {
      ReactDOM.findDOMNode(this).scrollTop = this.props.scrollTop
    }
    if (this.props.scrollLeft) {
      ReactDOM.findDOMNode(this).scrollLeft = this.props.scrollLeft
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scrollTop !== this.props.scrollTop) {
      ReactDOM.findDOMNode(this).scrollTop = this.props.scrollTop
    }
    if (prevProps.scrollLeft !== this.props.scrollLeft) {
      ReactDOM.findDOMNode(this).scrollLeft = this.props.scrollLeft
    }
  }

  render() {
    return {...this.props.children}
  }
}


/**
 * Component for scrollTop animation
 * (just cover your scrollContainer container with it and change scrollTop prop of MotionScroll)
 *
 * @param {Object} props of component
 * @returns {XML} JSX component
 * @constructor
 */
const MotionScroll = (props) => {
  const motionStyle = {
    ...typeof(props.scrollTop) !== "undefined" ? {scrollTop: props.animate ? spring(props.scrollTop) : props.scrollTop} : {},
    ...typeof(props.scrollLeft) !== "undefined" ? {scrollLeft: props.animate ? spring(props.scrollLeft) : props.scrollLeft} : {}
  }
  return (
    <Motion defaultStyle={{scrollLeft: 0, scrollTop: 0}} style={motionStyle} onRest={props.onRest}>
      {(style) => {
        return <ScrollSink {...props} scrollTop={style.scrollTop} scrollLeft={style.scrollLeft}>{props.children}</ScrollSink>
      }}
    </Motion>
  )
}

MotionScroll.propTypes = {
  scrollTop: PropTypes.number,
  scrollLeft: PropTypes.number,
  children: PropTypes.any.isRequired,
  animate: PropTypes.bool,
  onRest: PropTypes.func,
}

MotionScroll.defaultProps = {
  animate: true
}

export default MotionScroll
