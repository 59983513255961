import React from 'react'

export function bookmark() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{enableBackground:"new 0 0 24 24"}} xmlSpace="preserve">
      <g className="inactive" stroke="none" fill="none">
        <path className="stroke" strokeWidth="2" d="M6,3 L18,3 C18.6,3 19,3.4 19,4 L19,21.2 L12,17.2 L5,21.2 L5,4 C5,3.4 5.5,3 6,3 Z" ></path>
      </g>
      <g className="active" stroke="none" fill="none">
        <path className="stroke fill" d="M6,3 L18,3 C18.6,3 19,3.4 19,4 L19,21.2 L12,17.2 L5,21.2 L5,4 C5,3.4 5.5,3 6,3 Z"></path>
      </g>
    </svg>
  )
}

