import React from 'react'

export function flag() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{enableBackground:"new 0 0 24 24"}} xmlSpace="preserve">
      <g className="inactive" stroke="none" fill="none">
        <polygon className="stroke" strokeWidth="2" points="5 21 5 3 18.5 3 15 7.7 18.5 13 5 13"></polygon>
      </g>
      <g className="active" stroke="none" fill="none">
        <polygon className="stroke fill" strokeWidth="2" points="5 21 5 3 18.5 3 15 7.7 18.5 13 5 13"></polygon>
      </g>
    </svg>
  )
}
