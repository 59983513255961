import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getConfig } from 'tw-oi-core'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { trackBrowseTopicClick, trackBrowseFolderClick, trackPublicationClick } from 'tw-oi-core/services/analytics'

import { BRAND_INFINITI, ROUTE } from '../config'

import { connect } from 'react-redux'

import TopicsList from '../components/TopicsList'
import ScreenHead from '../components/ScreenHead'
import Media from '../components/Media'
import Loader from '../components/Loader'
import ScreenContainer from '../components/ScreenContainer'
import BreadCrumb from '../components/BreadCrumb'
import { parseLocationPreviewParams} from '../utils/publication'

import '../styles/Topics.scss'
import InfinitiTopicsList from '../components/InfinitiTopicsList/InfinitiTopicsList'

class Browse extends Component {
  static propTypes = {
    fetching: PropTypes.bool,
    topics: ImmutablePropTypes.list,
    folders: ImmutablePropTypes.list,
    publications: ImmutablePropTypes.list,
    bundles: ImmutablePropTypes.list,
    foldersIndex: ImmutablePropTypes.map,
    contentIndex: ImmutablePropTypes.map,
    currentFolder: PropTypes.string,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    match: PropTypes.object.isRequired,
    baseRoute: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    userMedia: PropTypes.shape({
      isDesktop: PropTypes.bool,
    }),
  }

  constructor(props) {
    super(props)

    const { BROWSE_ROOT_KEY } = getConfig()
    const { foldersIndex, topics, baseRoute, match, history } = props

    const folderId = match.params.folderId || BROWSE_ROOT_KEY
    let activeFolder = foldersIndex && foldersIndex.size ? foldersIndex.get(folderId) : null
    if (!activeFolder) {
      activeFolder = topics.find(item => item.get('resourceKey') === match.params.folderId)
      // redirect to topic if trying to opend folder with id of topic
      if (activeFolder) {
        const url = `${baseRoute}${ROUTE.BROWSE}${ROUTE.TOPIC}/${match.params.folderId}`
        history.replace(url)
        activeFolder = null
      }
    }

    this.state = {
      sortDescend: false,
      activeFolder,
      parentFolder: activeFolder
        ? foldersIndex.find(folder => folder.get('contents').keySeq().contains(activeFolder.get('resourceKey')))
        : null,
    }
  }

  componentDidUpdate(prevProps) {
    const { BROWSE_ROOT_KEY } = getConfig()

    if (prevProps.match.params.folderId !== this.props.match.params.folderId) {
      const { foldersIndex, match } = this.props

      const folderId = match.params.folderId || BROWSE_ROOT_KEY
      const activeFolder = foldersIndex && foldersIndex.size ? foldersIndex.get(folderId) : null

      this.setState({
        activeFolder,
        parentFolder: activeFolder
          ? foldersIndex.find(folder => folder.get('contents').keySeq().contains(activeFolder.get('resourceKey')))
          : null,
      })
    }
  }

  /**
   * Triggers browse analytics event when item is clicked
   *
   * @param {Immutable.Map} topic
   */
  trackItem(topic) {
    switch (topic.get('type')) {
      case 'folder':
        trackBrowseFolderClick(topic.get('title'))
        break
      case 'topic': {
        const pubId = this.props.contentIndex[topic.get('id')].publication.persistentId
        const topicPub = this.props.publications.toJS().find(pub => pub.persistentId === pubId)

        trackBrowseTopicClick(topic.get('title'), topicPub.title)
        break
      }
      case 'publication':
        trackPublicationClick(topic.get('title'))
        break
      default:
      // Do nothing in case of unsupported content type
    }
  }

  render() {
    const { BROWSE_ROOT_KEY } = getConfig()
    const {
      currentBrand,
      currentYear,
      currentModel,
      baseRoute,
      fetching,
      match,
      userMedia,
      foldersIndex,
      publications,
      history,
      bundles
    } = this.props
    const contents = this.state.activeFolder ? this.state.activeFolder.get('contents') : null
    const { parentFolder, activeFolder } = this.state

    const { location } = history
    const queryParams = parseLocationPreviewParams(location)
    if (!activeFolder) {
      return (
        <Loader type='status' className='inverse'>
          {bundles?.size && !publications?.size ? 'Coming soon' : 'Folder not found'}
        </Loader>
      )
    }

    const topicsList =
      currentBrand === BRAND_INFINITI ? (
        <InfinitiTopicsList
          isDesktop={userMedia.isDesktop}
          isRoot={this.state.activeFolder.get('type') === 'root'}
          topics={contents}
          baseRoute={baseRoute + ROUTE.BROWSE}
          publications={publications}
          onClick={topic => this.trackItem(topic)}
          isPreview={queryParams?.preview}
        />
      ) : (
        <TopicsList
          isDesktop={userMedia.isDesktop}
          topics={contents}
          baseRoute={baseRoute + ROUTE.BROWSE}
          publications={publications}
          onClick={topic => this.trackItem(topic)}
          className={!parentFolder && publications.size > 1 ? 'publications' : ''}
          isPreview={queryParams?.preview}
        />
      )

    let backRoute = null

    if (parentFolder) {
      backRoute = `${baseRoute}${ROUTE.BROWSE}${ROUTE.FOLDER}/${parentFolder.get('resourceKey') || BROWSE_ROOT_KEY}`
    }

    return (
      <div className='Topics'>
        <ScreenHead
          back={backRoute}
          backTitle={this.state.parentFolder ? this.state.parentFolder.get('title', 'Manuals') : 'Manuals'}
        >
          <em>
            {currentYear} {currentBrand}
          </em>{' '}
          {currentModel}
        </ScreenHead>
        <div className='screen-content'>
          <ScreenContainer currentUrl={match.url}>
            <div className='topics'>
              <Media type='desktop'>
                <BreadCrumb
                  activeFolder={activeFolder}
                  foldersIndex={foldersIndex}
                  baseRoute={baseRoute}
                  history={history}
                />
              </Media>
              {fetching ? <Loader className='inverse' /> : topicsList}
            </div>
          </ScreenContainer>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ contents, vehicle, user }) {
  const {
    topics,
    folders,
    foldersIndex,
    contentIndex,
    publications,
    fetching,
    currentAsset,
    fetchingAsset,
    currentFolder,
    bundles
  } = contents
  const { currentYear, currentModel, currentBrand } = vehicle
  const { media: userMedia } = user

  return {
    topics,
    folders,
    foldersIndex,
    contentIndex,
    publications,
    fetching,
    currentAsset,
    fetchingAsset,
    currentYear,
    currentModel,
    currentBrand,
    currentFolder,
    userMedia,
    bundles
  }
}

export default connect(mapStateToProps)(Browse)
