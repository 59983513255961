import React from 'react'

export function driverSeatInfiniti() {
  return (
    <svg viewBox='0 0 40 40' style={{enableBackground:"new 0 0 40 40"}}  fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20.4977 39.044C10.3973 39.044 2.17969 30.8268 2.17969 20.726C2.17969 10.6252 10.3973 2.40802 20.4977 2.40802C30.5981 2.40802 38.8157 10.6256 38.8157 20.726C38.8157 30.8264 30.5981 39.044 20.4977 39.044ZM20.4977 4.07322C11.3153 4.07322 3.84489 11.5436 3.84489 20.726C3.84489 29.9084 11.3153 37.3788 20.4977 37.3788C29.6801 37.3788 37.1505 29.9084 37.1505 20.726C37.1505 11.5436 29.6801 4.07322 20.4977 4.07322Z'
        fill='#020B24'
        className="fill"
      />
      <path
        d='M18.8336 34.9785L17.9304 34.8757C14.7064 34.5089 11.7344 32.5585 9.33479 29.2349C7.55679 26.7725 6.71759 24.4389 6.68279 24.3409L6.35199 23.4097C6.31999 23.3193 6.38519 23.2233 6.47919 23.2233H9.88679C15.8436 23.2233 18.8332 29.1545 18.8332 32.4749V34.9777L18.8336 34.9785ZM8.68839 24.8893C9.73279 27.1205 12.5112 32.0033 17.2068 33.0673V32.4757C17.2068 30.3165 15.0296 24.8893 9.88719 24.8893H8.68839Z'
        fill='#020B24'
        className="fill"
      />
      <path
        d='M22.1625 34.9784V32.4756C22.1625 29.1552 25.1521 23.224 31.1089 23.224H34.5165C34.6105 23.224 34.6761 23.3196 34.6437 23.4104L34.3129 24.3416C34.2781 24.4396 33.4389 26.7732 31.6609 29.2356C29.2613 32.5592 26.2893 34.5096 23.0653 34.8764L22.1621 34.9792L22.1625 34.9784ZM31.1885 24.8892C26.0005 24.8892 23.8037 30.3164 23.8037 32.4756V33.0672C28.5409 32.0032 31.3441 27.1204 32.3981 24.8892H31.1885Z'
        fill='#020B24'
        className="fill"
      />
      <path
        d='M34.6503 19.2908C34.2667 15.4704 32.5843 12.2176 29.7847 9.8832C27.2231 7.7472 23.9255 6.5708 20.4991 6.5708C17.0727 6.5708 13.7751 7.7472 11.2135 9.8832C8.41389 12.2176 6.73149 15.4704 6.34789 19.2908L6.21949 20.5716C6.20829 20.684 6.32669 20.7624 6.42429 20.7084L7.59309 20.0604C7.64909 20.0296 13.2519 16.9692 20.4991 16.9692V15.3584C15.0071 15.3584 10.1395 17.1864 7.90989 18.1496C9.04629 13.1268 13.6999 8.2364 20.4991 8.2364C27.2983 8.2364 31.9523 13.1268 33.0887 18.1496C30.8587 17.1864 25.9911 15.3584 20.4995 15.3584V16.9692C27.7467 16.9692 33.3495 20.03 33.4055 20.0604L34.5743 20.7084C34.6723 20.7628 34.7903 20.684 34.7791 20.5716L34.6507 19.2908H34.6503Z'
        fill='#020B24'
        className="fill"
      />
      <path
        d='M20.498 24.8892C23.346 24.8892 25.494 23.6364 25.494 21.9748C25.494 20.3132 23.3464 19.0604 20.498 19.0604C17.6496 19.0604 15.502 20.3132 15.502 21.9748C15.502 23.6364 17.6496 24.8892 20.498 24.8892ZM20.498 20.5748C22.6392 20.5748 23.9496 21.4812 23.9496 21.9752C23.9496 22.4692 22.6392 23.3756 20.498 23.3756C18.3568 23.3756 17.0464 22.4692 17.0464 21.9752C17.0464 21.4812 18.3568 20.5748 20.498 20.5748Z'
        fill='#020B24'
        className="fill"
      />
    </svg>
  )
}
