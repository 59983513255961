import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as UserActions from 'tw-oi-core/actions/UserActions'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { isAndroid } from '../utils/wrapper'

import { ROUTE } from '../config'

import Icon from '../components/Icon'

import "../styles/Promo.scss"

class SunsetPromo extends Component {

  static propTypes = {
    promoSkipped: PropTypes.bool,
    UserActions: PropTypes.shape({
      skipPromo: PropTypes.func.isRequired
    }),
    currentBrand: PropTypes.string.isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        referrer: PropTypes.string
      })
    })
  }

  onSkipClick() {
    const { skipPromo } = this.props.UserActions
    skipPromo()
  }

  render() {
    const { location } = this.props

    return (
      <div className="Promo Promo-sunset">
        <div className="container">
          <Link onClick={this.onSkipClick.bind(this)} to={(location && location.state && location.state.referrer) || ROUTE.INDEX} className="close"><Icon type="cancel" /></Link>
          <div className="head">
            <div className="logo"/>
            <h1>We&apos;ve Moved!</h1>
          </div>
          <div className="text">
            The Quick Guide is now included in the new MyNissan App! Please be aware we will be removing this app from the app store on 3.31.21
          </div>
          <div className={classNames("screenshot", isAndroid(navigator.userAgent) ? "android" : "ios")}>
            <button className="download">&nbsp;</button>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({vehicle}) {
  return {
    currentBrand: vehicle.currentBrand,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SunsetPromo)
