import React from 'react'
import copy from 'copy-to-clipboard'
import PropTypes from 'prop-types'

import { CopyLinkIcon } from '../../components/Icons'
import { isCopyPreviewLinkAllowed } from '../../utils/topics'

import './CopyLinkButton.scss'

const CopyLinkButton = ({ link }) => {
  const allowCopyPreviewList = isCopyPreviewLinkAllowed(window?.location?.host)

  if (!allowCopyPreviewList) return null

  return (

    <button className='CopyLinkBtn' onClick={(ev) => {
      ev.preventDefault()
      const absoluteLink = `${window.location.origin}/#${link}`
      const [part1, part2] = absoluteLink.split('?')
      const queryParams = part2 ? [part2, 'preview=true'].join('&') : 'preview=true'
      copy(`${part1}?${queryParams}`)
    }}>
      <CopyLinkIcon />
    </button>
  )
}

CopyLinkButton.propTypes = {
  link: PropTypes.string,
}

export default CopyLinkButton
