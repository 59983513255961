/**
 * Detect mobile operating system from UA
 *
 * This function returns one of 'ios', 'android' or null
 *
 * @param {string} ua user agent
 * @returns {string}|null
 */
export function getMobileOperatingSystem(ua) {

  if (/android/i.test(ua)) {
    return 'android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
    return 'ios'
  }

  return null
}

/**
 * Checks whether we are running inside the WebView mobile wrapper
 *
 * @param {string} ua user agent
 * @returns {boolean}
 */
export function isWrapper(ua) {

  if (/\bwrapper\b|\bdroid\b/i.test(ua)) { // UA contains "wrapper" - definitely inside the wrapper
    return true
  }

  // if UA doesn't contains "wrapper", but on iOS 12 ...
  if (/\b12_\d*_*\d*\b/i.test(ua)) {
    // ...and isn't Chrome/Firefox/Safari (webview doesn't have "Safari" string in UA) - we MAY BE in wrapper
    return !/\bSafari\b/i.test(ua)
  }

  return false // we are in browser
}

/**
 * Is Android?
 *
 * @param {string} ua user agent
 * @returns {boolean}
 */
export function isAndroid(ua) {
  return getMobileOperatingSystem(ua) === 'android'
}

/**
 * Is iOS?
 *
 * @param {string} ua user agent
 * @return {boolean}
 */
export function isIos(ua) {
  return getMobileOperatingSystem(ua) === 'ios'
}

/**
 * Is Android wrapper?
 *
 * @param {string} ua
 * @returns {boolean}
 */
export function isAndroidWrapper(ua) {
  return isWrapper(ua) && isAndroid(ua)
}

/**
 * Is iOS wrapper?
 *
 * @param {string} ua
 * @return {boolean}
 */
export function isIosWrapper(ua) {
  return isWrapper(ua) && isIos(ua)
}
