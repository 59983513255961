import React from 'react'

export function listTopic() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 25" style={{enableBackground: "new 0 0 20 25"}} xmlSpace="preserve">
      <g className="fill">
        <path d="M3,2 C2.51848522,2 2,2.54002055 2,3 L2,22 C2,22.4542863 2.52490946,23 3,23 L17,23 C17.4815148,23 18,22.4599794 18,22 L18,8 L18.2243436,9.2979399 L9.78987599,1.51525596 L11,2 L3,2 Z M12,0 L20,8 L20,22.2185358 C20,23.5897343 18.9590987,25 17.4968606,25 L2.63142135,25 C1.17812747,25 0,23.8849777 0,22.5172255 L0,2.65518826 C0,1.28398979 1.63414625,0 3.09638437,0 L12,0 Z" fillRule="nonzero"></path>
        <path d="M19,10 L18.7547866,10 L17.9005877,8.0862069 L13,8 C12,8 12,7 12,7 L12,2 L10,0.461580985 L10,1.50101801 L10,8 C10,9.17713036 10.736216,10 12,10 L18,10 L19,10 Z" fillRule="nonzero"></path>
        <rect x="4" y="13" width="12" height="2" rx="1"></rect>
        <rect x="4" y="18" width="12" height="2" rx="1"></rect>
      </g>
    </svg>
  )
}
