/*eslint no-restricted-globals: 0*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { sortItemsByTitle, filterTopicsByField } from 'tw-oi-core/utils/data'

import { MESSAGE } from '../config'
import { checkGlobals } from 'tw-oi-core/utils/ui'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as UserActions from 'tw-oi-core/actions/UserActions'

import ScreenHead from '../components/ScreenHead'

import TopicList from '../components/TopicsList'

import '../styles/Topics.scss'

class Favorites extends Component {

  static propTypes = {
    topics: ImmutablePropTypes.list,
    favorites: PropTypes.array,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    UserActions: PropTypes.shape({
      addFavorite: PropTypes.func.isRequired,
      removeFavorite: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired
    }).isRequired,
  }

  onBookmarkClick(e) {
    const { addFavorite, removeFavorite } = this.props.UserActions
    const { favorites } = this.props
    const topicId = e.currentTarget.dataset.topicid

    if (topicId) {
      if (favorites.indexOf(topicId) >= 0) {
        if (checkGlobals(['cordova', 'navigator'])) {
          navigator.notification.confirm(MESSAGE.CONFIRM_REMOVE_FAVORITE, button => button === 1 && removeFavorite(topicId), "Remove", ["Remove", "Cancel"])
        } else
        if (confirm(MESSAGE.CONFIRM_REMOVE_FAVORITE)) removeFavorite(topicId)
      } else addFavorite(topicId)
    }
  }

  render() {
    const { topics, favorites, currentYear, currentBrand, currentModel, match } = this.props
    const filteredTopics = sortItemsByTitle(filterTopicsByField(topics, favorites, 'resourceKey'))

    return (
      <div className="Topics">
        <ScreenHead><em>{currentYear} {currentBrand}</em> {currentModel}</ScreenHead>
        <div className="screen-content">
          <div className="screen-container">
            <TopicList
              topics={filteredTopics}
              favorites={favorites}
              onBookmarkClick={this.onBookmarkClick.bind(this)}
              emptyText={MESSAGE.EMPTY_FAVORITES}
              baseRoute={match.url}
            />
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ contents, vehicle, user }) {
  const { topics } = contents
  const { currentYear, currentModel, currentBrand } = vehicle
  const { favorites } = user

  return { topics, currentYear, currentModel, currentBrand, favorites }
}

function mapDispatchToProps(dispatch) {
  return {
    UserActions: bindActionCreators(UserActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Favorites)
