import React from 'react'

export function topicInfiniti() {
  return (
    <svg
      style={{ enableBackground: 'new 0 0 1000 1000' }}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 1000 1000'
      xmlSpace='preserve'
    >
      <g id='Layer_1'></g>
      <g id='Layer_2'>
        <path
          d='M729.1146,42.62H229.1904c-23.1408,0-41.6951,18.5543-41.6951,41.6951v833.2764c0,22.9323,18.5543,41.6951,41.6951,41.6951
		h541.6193c22.9323,0,41.6951-18.5543,41.6951-41.6951V126.0102L729.1146,42.62z M229.1904,84.3151h541.6193v833.2764H229.1904
		V84.3151z M694.368,259.0175H319.3213v-41.6951H694.368V259.0175z M694.368,342.4077v41.6951H319.3213v-41.6951H694.368
		L694.368,342.4077z M569.2828,467.2845v41.6951H319.3213v-41.6951h250.17H569.2828z'
        />
      </g>
    </svg>
  )
}
