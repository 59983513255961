import React from 'react'

export function rotateDevice() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100"  style={{enableBackground:"new 0 0 100 100"}} xmlSpace="preserve">
      <path className="fill" d="M54.1260572,13.1737825 C57.0774588,10.2223808 61.8613149,10.2210694 64.8149176,13.1746722 L86.8253272,35.1850818 C89.7772203,38.1369748 89.7830542,42.9171051 86.8262171,45.8739424 L45.8739418,86.8262176 C42.9225402,89.7776192 38.138684,89.7789306 35.1850813,86.8253279 L13.1746717,64.8149183 C10.2227786,61.8630252 10.2169447,57.0828949 13.1737819,54.1260577 L54.1260572,13.1737825 Z M54.8060814,18.6250381 L18.9283636,54.5027558 L45.4972436,81.0716358 L81.3749614,45.193918 L54.8060814,18.6250381 Z M26.2400238,74.7818557 C27.6509456,76.1927775 29.9507302,76.1805493 31.3767361,74.7545434 C32.8027422,73.3285373 32.8149702,71.0287529 31.4040484,69.6178311 C29.9931265,68.2069092 27.6933422,68.2191373 26.2673362,69.6451434 C24.8413301,71.0711493 24.8291019,73.3709338 26.2400238,74.7818557 Z M9.48587027,29.9511808 L6.2787392,29.9511808 C5.20969551,29.9511808 4.67517366,30.4857026 5.20969551,31.0202245 L14.8310887,40.6416177 L24.4524819,31.0202245 C24.9870038,30.4857026 24.4524819,29.9511808 23.3834382,29.9511808 L20.1763072,29.9511808 C18.6198919,23.7255199 25.2787598,19.0179781 34.608397,19.7952657 C36.2956454,19.9358367 36.3327862,19.1319121 35.6774407,18.726222 C24.4524819,11.777438 9.48587027,16.0536128 9.48587027,29.9511808 Z M90.5141297,69.5961155 L93.7212608,69.5961155 C94.7903045,69.5961155 95.3248263,69.0615937 94.7903045,68.5270718 L85.1689113,58.9056786 L75.5475181,68.5270718 C75.0129962,69.0615937 75.5475181,69.5961155 76.6165618,69.5961155 L79.8236928,69.5961155 C81.3801081,75.8217764 74.7212402,80.5293182 65.391603,79.7520306 C63.7043546,79.6114595 63.6672138,80.4153841 64.3225593,80.8210742 C75.5475181,87.7698582 90.5141297,83.4936835 90.5141297,69.5961155 Z"/>
    </svg>
  )
}

