import React from 'react'

export function quickGuide() {
  return (
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M4.16797 1.83301C4.16797 1.28072 4.61568 0.833008 5.16797 0.833008H24.8346C25.3869 0.833008 25.8346 1.28072 25.8346 1.83301V24.833C25.8346 25.3853 25.3869 25.833 24.8346 25.833H5.16797C4.61568 25.833 4.16797 25.3853 4.16797 24.833V1.83301ZM11.0012 16.8005V10.1995C11.0012 9.43703 11.8203 8.95506 12.4869 9.32536L18.4277 12.6258C19.1135 13.0068 19.1135 13.9932 18.4277 14.3742L12.4869 17.6746C11.8203 18.0449 11.0012 17.563 11.0012 16.8005ZM7.5013 28.1663V27.4997H26.5013C27.0536 27.4997 27.5013 27.052 27.5013 26.4997V3.33301H28.168C28.7203 3.33301 29.168 3.78072 29.168 4.33301V28.1663C29.168 28.7186 28.7203 29.1663 28.168 29.1663H8.5013C7.94902 29.1663 7.5013 28.7186 7.5013 28.1663Z" fill="#0073BF" className='fill'/>
<rect x="7.5" y="1.66699" width="3.33333" height="1.66667" fill="white" className='fill-inverted'/>
<rect x="7.5" y="23" width="3.33333" height="1.66667" fill="white" className='fill-inverted'/>
<rect x="13.332" y="1.66699" width="3.33333" height="1.66667" fill="white" className='fill-inverted'/>
<rect x="13.332" y="23" width="3.33333" height="1.66667" fill="white" className='fill-inverted'/>
<rect x="19.168" y="1.66699" width="3.33333" height="1.66667" fill="white" className='fill-inverted'/>
<rect x="19.168" y="23" width="3.33333" height="1.66667" fill="white" className='fill-inverted'/>
</svg>
  )
}
