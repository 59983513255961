import React from 'react'

export function explore360Infiniti() {
  return (
    <svg viewBox='0 0 918 392' style={{enableBackground:"new 0 0 918 392"}} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M917.212 203.987C916.751 173.733 914.201 150.578 899.898 132.215C882.093 109.335 850.924 100.004 792.341 100.004C784.498 100.004 771.192 99.9225 762.627 99.8683L754.564 99.8276C718.79 99.8276 682.844 91.6531 654.225 82.7242C640.733 78.3454 627.841 71.5908 611.68 63.048C585.686 49.3049 550.086 30.479 490.017 10.7273C442.62 -4.86385 268.578 -3.20234 138.641 14.0842C115.125 17.2106 47.1966 67.2255 8.79527 96.7631L0.664062 103.019V203.002H0.728462V245.658L84.0483 328.979H107.628C116.916 364.862 149.471 391.475 188.215 391.475C226.959 391.475 259.514 364.862 268.805 328.979H649.258C658.548 364.862 691.101 391.475 729.844 391.475C768.591 391.475 801.143 364.862 810.434 328.979H834.004C867.499 328.979 917.334 306.791 917.334 245.651V203.987L917.212 203.987ZM188.215 349.811C165.242 349.811 146.552 331.122 146.552 308.147C146.552 285.172 165.242 266.483 188.215 266.483C211.191 266.483 229.878 285.172 229.878 308.147C229.878 331.122 211.191 349.811 188.215 349.811ZM729.844 349.811C706.871 349.811 688.181 331.122 688.181 308.147C688.181 285.172 706.871 266.483 729.844 266.483C752.821 266.483 771.511 285.172 771.511 308.147C771.511 331.122 752.821 349.811 729.844 349.811ZM875.671 245.651C875.671 283.701 843.661 287.159 833.848 287.315H810.435C801.144 251.432 768.591 224.819 729.844 224.819C691.101 224.819 658.548 251.432 649.258 287.315H268.805C259.514 251.432 226.959 224.819 188.215 224.819C149.471 224.819 116.916 251.432 107.628 287.315H42.3918V137.321L42.3308 137.276V123.581C84.0992 91.9176 132.859 58.311 144.134 55.3847C281.036 37.186 443.494 39.2917 476.999 50.3052C533.725 68.9616 566.148 86.1057 592.203 99.8827C610.127 109.36 625.61 117.546 643.351 123.045L643.354 123.037L643.358 123.055C676.818 134.455 708.177 141.492 754.564 141.492L762.376 141.532C771.016 141.587 784.43 141.668 792.341 141.668C834.723 141.668 858.452 146.795 867.024 157.807C875.651 168.881 875.651 192.331 875.651 224.819H875.671L875.671 245.651Z'
        fill='#000'
        className='fill'
      />
    </svg>
  )
}
