import React from 'react'

export function searchDot() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{enableBackground:"new 0 0 24 24"}} xmlSpace="preserve">
      <g className="inactive" stroke="none" fill="none">
        <g transform="translate(3.000000, 3.000000)">
          <path className="stroke" d="M12,12 L18.2,18.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
          <circle className="stroke" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" cx="7" cy="7" r="7"></circle>
          <circle className="fill" cx="7" cy="7" r="2"></circle>
        </g>
      </g>
      <g className="active" stroke="none" fill="none">
        <g transform="translate(2.000000, 2.000000)">
          <path className="stroke" d="M13,13 L19.2,19.2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
          <path className="fill" d="M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z M8,10 C9.1045695,10 10,9.1045695 10,8 C10,6.8954305 9.1045695,6 8,6 C6.8954305,6 6,6.8954305 6,8 C6,9.1045695 6.8954305,10 8,10 Z"></path>
        </g>
      </g>
    </svg>
  )
}

