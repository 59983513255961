import React from 'react'

export function videoInfiniti() {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      xmlSpace='preserve'
      style={{ enableBackground: 'new 0 0 1000 1000' }}
      viewBox='0 0 1000 1000'
    >
      <g id='Layer_1'></g>
      <g id='Layer_2'>
        <path
          d='M500.0034,958.07c-252.4211,0-457.784-205.363-457.784-457.784s205.363-457.784,457.784-457.784
		s457.7773,205.3629,457.7773,457.784S752.4244,958.07,500.0034,958.07z M500.0034,84.1686
		c-229.4447,0-416.1173,186.6726-416.1173,416.1173s186.6726,416.1173,416.1173,416.1173S916.114,729.7307,916.114,500.286
		S729.4481,84.1686,500.0034,84.1686z M407.1554,682.0351V317.9604l256.8902,182.0407L407.1554,682.0351z M448.822,398.554v202.8876
		l143.1614-101.4404L448.822,398.554z'
        />
      </g>
    </svg>
  )
}
