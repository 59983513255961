import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import {ROUTE} from '../config'
import Icon from '../components/Icon'

import '../styles/NavBar.scss'

export default class NavBar extends Component {
  static propTypes = {
    baseRoute: PropTypes.string.isRequired,
    explore: PropTypes.bool,
    topPicks: PropTypes.bool,
    favorites: PropTypes.bool
  }

  static defaultProps = {
    explore: false,
    topPicks: false,
    favorites: false
  }

  render() {
    const { explore, topPicks, favorites } = this.props

    return <div className="NavBar">
      {explore && <NavLink className="nav-link" to={`${this.props.baseRoute}${ROUTE.EXPLORE}`}><Icon type="binocular"/>Explore</NavLink>}
      <NavLink className="nav-link" to={`${this.props.baseRoute}${ROUTE.BROWSE}`}><Icon type="book"/>Manuals</NavLink>
      <NavLink className="nav-link" to={`${this.props.baseRoute}${ROUTE.SEARCH}`}><Icon type="search-dot"/>Search</NavLink>
      {topPicks && <NavLink className="nav-link" to={`${this.props.baseRoute}${ROUTE.TOPPICKS}`}><Icon type="flag"/>Top Picks</NavLink>}
      {favorites && <NavLink className="nav-link" to={`${this.props.baseRoute}${ROUTE.FAVORITES}`}><Icon type="bookmark"/>Favorites</NavLink>}
    </div>
  }
}
